import React from "react";

export function Tooltip({ ...props }) {

    const getTooltipClassName = () => {
        if (props.left) return "info-icon-text-left";
        // else if (props.right) return "info-icon-text-right";
        else return "info-icon-text"
    }

    return (
        <div className="tool-tip ps-1">
            <svg fill="#969492" xmlns="http://www.w3.org/2000/svg" className="info-icon"
                width="15px" height="15px" viewBox="0 0 52 52" enableBackground="new 0 0 52 52">
                <path d="M26,2C12.7,2,2,12.7,2,26s10.7,24,24,24s24-10.7,24-24S39.3,2,26,2z M26,14.1c1.7,0,3,1.3,3,3s-1.3,3-3,3
                    s-3-1.3-3-3S24.3,14.1,26,14.1z M31,35.1c0,0.5-0.4,0.9-1,0.9h-3c-0.4,0-3,0-3,0h-2c-0.5,0-1-0.3-1-0.9v-2c0-0.5,0.4-1.1,1-1.1l0,0
                    c0.5,0,1-0.3,1-0.9v-4c0-0.5-0.4-1.1-1-1.1l0,0c-0.5,0-1-0.3-1-0.9v-2c0-0.5,0.4-1.1,1-1.1h6c0.5,0,1,0.5,1,1.1v8
                    c0,0.5,0.4,0.9,1,0.9l0,0c0.5,0,1,0.5,1,1.1V35.1z"/>
            </svg>
            <div className={getTooltipClassName()} style={{width: props.width}}>
                {props.text.split("\n").map((line, index) => (
                    <React.Fragment key={index}>
                    {line}
                    <br />
                    </React.Fragment>
                ))}
            </div>
        </div>

    );
}
