import {Table} from "react-bootstrap";
import {Fragment, useContext} from "react";
import "react-datepicker/dist/react-datepicker.css";
import "react-datetime/css/react-datetime.css";
import 'react-loading-skeleton/dist/skeleton.css'
import {TsAdminContext} from "../../context/ts-admin";

export function ExSettingTabCompany({
                                        onShowModalCreate,
                                        callbackEditCompany,
                                        callbackEditPattern,
                                        patterns,
                                        records
                                    }) {
    const columns = [
        {
            dataField: 'company_id',
            text: '会社コード',
            sort: false
        }, {
            dataField: 'device_id',
            text: '端末ID',
            sort: false
        },
        /*{
            dataField: 'pattern_name',
            text: 'お弁当パターン名',
            sort: false
        },
        {
            dataField: 'pattern_1',
            text: 'お弁当A',
            sort: false
        },
        {
            dataField: 'pattern_2',
            text: 'お弁当B',
            sort: false
        },
        {
            dataField: 'pattern_3',
            text: 'お弁当C',
            sort: false
        },
        {
            dataField: 'pattern_4',
            text: 'お弁当D',
            sort: false
        },*/

    ];

    const [orgInfo, setOrgInfo, userInfo] = useContext(TsAdminContext);


    const editCompany = (e) => {
        callbackEditCompany(e);
    }

    return (
        <Fragment>
            <div className="tab-company bg-white pb-0">
                {
                    userInfo.isSuperAdmin ?
                        <a className='btn-add-company btn-add-company-disable'>
                            <svg xmlns="http://www.w3.org/2000/svg" width="17px" height="17px" viewBox="0 0 17 17"
                                 fill="none">
                                <path fillRule="evenodd" className='plus-icon' clipRule="evenodd"
                                      d="M14.0495 2.93416C10.9764 -0.106227 6.00717 -0.106227 2.9341 2.93416C-0.106288 6.00724 -0.106288 10.9765 2.9341 14.0495C6.00717 17.1226 10.9764 17.1226 14.0168 14.0495C17.0899 10.9765 17.0899 6.00724 14.0495 2.93416ZM13.7226 9.1457C13.7226 9.34185 13.5918 9.47262 13.3956 9.47262H9.79948C9.63602 9.47262 9.47256 9.63608 9.47256 9.79954V13.3957C9.47256 13.5592 9.3091 13.7226 9.14564 13.7226H7.83794C7.64179 13.7226 7.51102 13.5919 7.51102 13.3957V9.79954C7.51102 9.60339 7.38025 9.47262 7.1841 9.47262H3.58794C3.39179 9.47262 3.26102 9.34185 3.26102 9.1457V7.838C3.26102 7.67454 3.42448 7.51108 3.58794 7.51108H7.1841C7.38025 7.51108 7.51102 7.38031 7.51102 7.18416V3.588C7.51102 3.42454 7.67448 3.26108 7.83794 3.26108H9.14564C9.3091 3.26108 9.47256 3.39185 9.47256 3.588V7.18416C9.47256 7.38031 9.60333 7.51108 9.79948 7.51108H13.3956C13.5591 7.51108 13.7226 7.67454 13.7226 7.838V9.1457Z"
                                />
                            </svg>
                            新規追加
                        </a>
                        :
                        <a className='btn-add-company' onClick={() => onShowModalCreate(true)}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="17px" height="17px" viewBox="0 0 17 17"
                                 fill="none">
                                <path fillRule="evenodd" className='plus-icon' clipRule="evenodd"
                                      d="M14.0495 2.93416C10.9764 -0.106227 6.00717 -0.106227 2.9341 2.93416C-0.106288 6.00724 -0.106288 10.9765 2.9341 14.0495C6.00717 17.1226 10.9764 17.1226 14.0168 14.0495C17.0899 10.9765 17.0899 6.00724 14.0495 2.93416ZM13.7226 9.1457C13.7226 9.34185 13.5918 9.47262 13.3956 9.47262H9.79948C9.63602 9.47262 9.47256 9.63608 9.47256 9.79954V13.3957C9.47256 13.5592 9.3091 13.7226 9.14564 13.7226H7.83794C7.64179 13.7226 7.51102 13.5919 7.51102 13.3957V9.79954C7.51102 9.60339 7.38025 9.47262 7.1841 9.47262H3.58794C3.39179 9.47262 3.26102 9.34185 3.26102 9.1457V7.838C3.26102 7.67454 3.42448 7.51108 3.58794 7.51108H7.1841C7.38025 7.51108 7.51102 7.38031 7.51102 7.18416V3.588C7.51102 3.42454 7.67448 3.26108 7.83794 3.26108H9.14564C9.3091 3.26108 9.47256 3.39185 9.47256 3.588V7.18416C9.47256 7.38031 9.60333 7.51108 9.79948 7.51108H13.3956C13.5591 7.51108 13.7226 7.67454 13.7226 7.838V9.1457Z"
                                />
                            </svg>
                            新規追加
                        </a>
                }
                {records && records.length > 0 ?
                    <div className='flex'>
                        <Table className="mt-3 rounded-0 table-company mb-0">
                            <thead>
                            <tr>
                                {
                                    columns.map((e, index) => {
                                        return (
                                            <th key={index} className={index === 3 ? "lg-column" : "xs-column"}>
                                                {e.text}
                                            </th>
                                        )
                                    })
                                }
                            </tr>
                            </thead>


                            <tbody>
                            {
                                records.map((item, key) => {
                                    return (
                                        <tr key={key}>
                                            <td className={key == records.length - 1 ? 'border-0' : ''}>
                                                {
                                                    userInfo.isSuperAdmin ?
                                                        <a className="label-title">{item?.company_id} </a>
                                                        :
                                                        <a className="label-title"
                                                           onClick={() => editCompany(item)}>{item?.company_id} </a>
                                                }

                                            </td>

                                            <td className={key == records.length - 1 ? 'border-0' : ''}>{item?.device_id}</td>

                                            {/*<td>
                                            {
                                                userInfo.isSuperAdmin ?
                                                    <a className="label-title">{item.pattern?.Name}</a>
                                                    :
                                                    <a className="label-title"
                                                       onClick={() => editPattern(item.pattern)}>{item.pattern?.Name}</a>
                                            }


                                        </td>

                                        <td>{patterns?.find(e => e.id === item.PatternId)?.pattern_1.value}</td>
                                        <td>{patterns?.find(e => e.id === item.PatternId)?.pattern_2.value}</td>
                                        <td>{patterns?.find(e => e.id === item.PatternId)?.pattern_3.value}</td>
                                        <td>{patterns?.find(e => e.id === item.PatternId)?.pattern_4.value}</td>
                                        */}
                                        </tr>
                                    )
                                })
                            }
                            </tbody>
                        </Table>

                        {/*{
                            records && records.length > 0 && <PaginationComponent
                                totalPage={Math.ceil(totalRecord / perPage)}
                                handlePage={setPage}
                                currentPage={pageNumber}
                            />
                        }*/}
                    </div>
                    :
                    <h6 className="text-center pt-5 fw-bold">データがありません。</h6>
                }


            </div>
        </Fragment>
    )

}