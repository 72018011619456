import {Fragment, useCallback, useContext, useEffect, useState} from "react";
import { Button, Card, Col, Form, Modal, Row, Spinner, Table } from "react-bootstrap";
import { getListOrg } from "../../api-service/list-org";
import { IconArrow } from "../../parts/icon";
import leftButton from "../../assets/icons/jump_left.svg";
import rightButton from "../../assets/icons/jump_right.svg";
import leftButtonEnable from "../../assets/icons/jump_left_enable.svg";
import rightButtonEnable from "../../assets/icons/jump_right_enable.svg";
import { toast } from "react-toastify";
import { format } from 'date-fns';
import { compareTwoDate } from "../../common";
import { generateSettingProvider } from "../../common";
import { debounce } from "lodash"
import {TsAdminContext} from "../../context/ts-admin";
import {createSearchParams, useNavigate} from "react-router-dom";
import {getPasswordTargetOrg, updateOrg} from "../../api-service/org-info";
import previewHidden from "../../assets/icons/preview-hidden.svg";
import preview from "../../assets/icons/preview.svg";
import base64 from 'base-64';
import { Tooltip } from 'react-tooltip';

export function AdminConsolePage() {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [currentItems, setCurrentItems] = useState<any>([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPage, setTotalPage] = useState(1);
    const [orgInfo, setOrgInfo, userInfo] = useContext(TsAdminContext);
    const [isShowModalPassword, setIsShowModalPassword] = useState(false);
    const [isShowPassword, setIsShowPassword] = useState(false);
    const [targetOrg, setTargetOrg] = useState<any>(null);

    useEffect(() =>  {
        if(userInfo) {
            console.log(userInfo.isSuperAdmin);
            if(!userInfo.isSuperAdmin) {
                window.location.href = '/404'
            }
        }
    },[userInfo])
    const columns = [
        {
            dataField: 'account_id',
            text: '取引先ID',
        }, {
            dataField: 'account_name',
            text: '取引先名',
        }, {
            dataField: 'org_id',
            text: '組織ID',
        }, {
            dataField: 'environment_type',
            text: '組織種別',
        },/* {
            dataField: 'connect_type',
            text: '接続先種別',
        },*/ {
            dataField: 'license_number',
            text: 'ライセンス数',
        }, {
            dataField: 'device_auth_number',
            text: '端末認証固有番号',
        }, {
            dataField: 'constract_date',
            text: '契約期間'
        }, {
            dataField: 'enable_flag',
            text: '状態'
        },{
            dataField: 'btn_export',
            text: 'プロバイダ設定ファイル'
        },{
            dataField: 'export_date',
            text: '出力日'
        },{
            dataField: 'btn_create_org',
            text: '組織登録'
        }];

    const [searchData, setSearchData] = useState({
        accountId: "",
        accountName: "",
        orgId: "",
        environmentType: "0",
        page: 1,
        showDisabledAccount: false,
    });

    const getListOrgData = async (params) => {
        await getListOrg({
            accountId: params.accountId,
            accountName: params.accountName,
            orgId: params.orgId,
            environmentType: params.environmentType,
            page: params.currentPage,
            showDisabledAccount: params.showDisabledAccount,
            limit: 20,
        }).then((response: any) => {
            setCurrentItems(response.data);
            setTotalPage(response.total_pages);
            setIsLoading(false);
        })
    };

    const fetchListOrg = async () => {
        const params = {...searchData, currentPage}
        setIsLoading(true);
        localStorage.removeItem('target-org');
        await getListOrgData(params);
    }

    const searchListOrg = () => {
        setCurrentPage(1);
        fetchListOrg();
    }

    useEffect(() =>  {
        fetchListOrg();
    }, [currentPage]);

    const handleToNextPage = debounce(async () => {
        setCurrentPage(currentPage + 1);
    }, 500);

    const handleToPrevPage = debounce(async () => {
        setCurrentPage(currentPage - 1);
    }, 500);

    const handleToFirstPage = debounce(async () => {
        setCurrentPage(1);
    }, 500);

    const handleToLastPage = debounce(async () => {
        setCurrentPage(totalPage);
    }, 500);

    const createOrgByExistsAccount = async (orgInfo) => {
        const accountData = {
            AccountId: orgInfo.AccountId,
            AccountName: orgInfo.AccountName,
            LicenseNumber: orgInfo.LicenseNumber,
            EndDate: orgInfo.EndDate,
            StartDate: orgInfo.StartDate
    }
        navigate({
            pathname: `/admin/create-org/${orgInfo.AccountId}`,
            search : `?${createSearchParams(accountData)}`
        });
    }

    const showOrgDevicePassword = (item) => {
        setTargetOrg(item)
        setIsShowPassword(false);
        setIsShowModalPassword(true);
    }

    const getPasswordOrgInfo = async () => {

        await getPasswordTargetOrg(targetOrg.OrgId).then(async (res: any) => {
            if(res.AdminPassword !== undefined) {
                setTargetOrg({
                    ...targetOrg, AdminPassword : res.AdminPassword
                })

            }
            setIsShowPassword(true);
        }).catch(err => {
            toast.error("Error : " + err.error);
        })

    }

    const handleCloseModal = () => {
        setIsShowModalPassword(false);
    }

    const downloadProviderFile = async (orgInfo) => {
        await updateOrg(orgInfo.OrgId, {
            ExportSettingTime: new Date().valueOf(),
            ExportSettingBy: orgInfo?.userInfo ? orgInfo?.userInfo?.display_name : ''
        }).then(async (res: any) => {
            const myMarkdownFile = require('../../assets/documents/provider.txt');
            const providerSetting = orgInfo.ProviderSetting;
            let contentFile = "";
            await fetch(myMarkdownFile)
                .then(response => response.text())
                .then(text => contentFile = text)
            const currentOrgInfo = res.currentOrgInfo.Attributes;
            const deviceAuthNumber = orgInfo.DeviceAuthNumber
            contentFile = contentFile.replaceAll("authen_number=authen_number", `authen_number=${deviceAuthNumber}`);
            if(process.env.REACT_APP_PITTOUCH_API_DEVICE) {
                contentFile = contentFile.replaceAll('{PITTOUCH_API_DEVICE}', process.env.REACT_APP_PITTOUCH_API_DEVICE);
            }
            if(currentOrgInfo.AdminPassword && currentOrgInfo.AdminPassword != '') {
                contentFile += `\r\nadmin.password=${currentOrgInfo.AdminPassword}`;
            }
            contentFile += generateSettingProvider(providerSetting);
            const element = document.createElement("a");
            const file = new Blob([contentFile], {type: 'text/plain'});
            element.href = URL.createObjectURL(file);
            element.download = `provider-${format(new Date(), "yyyy/MM/dd HH:mm")}.txt`;
            document.body.appendChild(element); // Required for this to work in FireFox
            element.click();
        }).catch(err => {
            toast.error("Error : " + err.error);
        })
    }

    const handleShowOrgDetail = (orgInfo) => {
        localStorage.setItem('target-org', orgInfo.OrgId);
        let features = ''
        features += 'top=0, left=0'
        features += ', fullscreen=yes';
        features += ', directories=no';
        features += ', location=no';
        features += ', menubar=no';
        features += ', resizable=no';
        features += ',height=' + window.screen.height;
        features += ',width=' + window.screen.width;
        features += ', scrollbars=no';
        features += ', status=no';
        features += ', toolbar=no';
        var win: any = window.open('/ts-connect?view=open', 'detail-org', features);
        win.moveTo(0,0);
    };
    const showPassword = () => {
        if (targetOrg.AdminPassword === undefined) {
            return 'パスワードを利用しないの設定です';
        } else {
            if (targetOrg.AdminPassword !== '') {
                return base64.decode(targetOrg.AdminPassword);
            }
            return 'パスワードを利用しないの設定です';
        }
    }
    return (
        <Fragment>
            <div className="admin-console">
                <Modal className="pt-5" show={isShowModalPassword} onHide={handleCloseModal}>
                    <Modal.Header closeButton>
                        パスワード参照
                    </Modal.Header>
                    <Modal.Body className="ps-5">    
                        <div className="d-flex pt-3">
                            <Form.Label className="mt-2 me-3 modal-form-label">
                                取引先名
                            </Form.Label>
                            <div className="d-block w-50">
                                <p className="py-2 item-label mb-0 modal-form-control">{targetOrg?.AccountName}</p>
                            </div>
                        </div>
                        <div className="d-flex pt-3">
                            <Form.Label className="mt-2 me-3 modal-form-label">
                                パスワード
                            </Form.Label>
                            { isShowPassword ?
                                <div className="d-flex w-50 align-items-center">
                                    <p className="py-2 item-label mb-0 modal-form-control">{showPassword()}</p>
                                    <img className="modal-preview-icon" src={previewHidden} onClick={() => setIsShowPassword(false)}/>
                                </div>
                                    :
                                <div className="d-flex w-50 align-items-center">
                                    <p className="py-2 item-label mb-0 modal-form-control">**********</p>
                                    <img className="modal-preview-icon" src={preview}
                                         onClick={getPasswordOrgInfo}/>
                                </div>
                            }
                        </div>
                        <div className="d-flex pt-3">
                            <p className="py-2 item-label mb-0 modal-form-control">※参照した履歴がログに記録されました</p>
                        </div>
                    </Modal.Body>
                    <Modal.Footer className="modal-footer">
                        <Button variant="light" size="sm" className="modal-btn modal-cancel-btn" onClick={handleCloseModal}>
                            閉じる
                        </Button>
                    </Modal.Footer>
                </Modal>
                <div className="mt-3">
                    <Card className="mt-3 card-container">
                        <Card.Body className="page-title">取引先一覧</Card.Body>
                    </Card>
                    <Form>
                        <Row className="d-flex align-items-center p-3">
                            <Col xs={12} md={6} lg={4} className="d-flex align-items-center col-2 pt-2">
                                <Form.Label className="mt-2 me-2 admin-form-label" htmlFor="accountId">
                                    取引先ID
                                </Form.Label>
                                <Form.Control className="admin-form-control" id="accountId"
                                    onChange={e => setSearchData({ ...searchData, accountId: e.target.value })}>
                                </Form.Control>
                            </Col>
                            <Col xs={12} md={6} lg={4} className="d-flex align-items-center col-2 pt-2">
                                <Form.Label className="mt-2 me-2 admin-form-label" htmlFor="accountName">
                                    取引先名
                                </Form.Label>
                                <Form.Control className="admin-form-control" id="accountName"
                                    onChange={e => setSearchData({ ...searchData, accountName: e.target.value })}>
                                </Form.Control>
                            </Col>
                            <Col xs={12} md={6} lg={4} className="d-flex align-items-center col-2 pt-2">
                                <Form.Label className="mt-2 me-2 admin-form-label" htmlFor="orgId">
                                    組織ID
                                </Form.Label>
                                <Form.Control className="admin-form-control" id="orgId"
                                    onChange={e => setSearchData({ ...searchData, orgId: e.target.value })}>
                                </Form.Control>
                            </Col>
                            <Col xs={12} md={6} lg="auto" className="d-flex align-items-center col-2 pt-2">
                                <Form.Label className="mt-2 me-2 admin-form-label" htmlFor="environmentType">
                                    組織種別
                                </Form.Label>
                                <Form.Check
                                    value="2"
                                    name="enviromentType"
                                    type="radio"
                                    id="environmentType1"
                                    label="本番環境"
                                    className="admin-form-checkbox pe-2"
                                    checked={searchData?.environmentType === "2"}
                                    onChange={e => setSearchData({ ...searchData, environmentType: e.target.value })}
                                />
                                <Form.Check
                                    value="1"
                                    name="enviromentType"
                                    type="radio"
                                    id="environmentType2"
                                    label="Sandbox"
                                    className="admin-form-checkbox pe-2"
                                    checked={searchData?.environmentType === "1"}
                                    onChange={e => setSearchData({ ...searchData, environmentType: e.target.value })}
                                />
                                <Form.Check
                                    value="0"
                                    name="enviromentType"
                                    type="radio"
                                    id="environmentType0"
                                    label="全て"
                                    className="admin-form-checkbox"
                                    checked={searchData?.environmentType === "0"}
                                    onChange={e => setSearchData({ ...searchData, environmentType: e.target.value })}
                                />
                            </Col>
                            <Col xs={8} md="auto" lg="auto" className="d-flex align-items-center col-1 pt-2">
                                <Form.Label className="mt-2 me-2 admin-form-label">
                                    無効組織
                                </Form.Label>
                                <input 
                                    className="admin-form-checkbox"
                                    type="checkbox" 
                                    checked={searchData.showDisabledAccount}
                                    id="showDisableAccounts" 
                                    onChange={e => setSearchData({ ...searchData, showDisabledAccount: e.target.checked })}/>
                                <label className="admin-form-label ps-1">含む</label>
                            </Col>
                            <Col xs={4} md="auto" lg="auto" className="align-items-center col-1 pt-2">
                                {
                                    isLoading ?
                                        <Button size={'sm'} className="button-search" disabled>
                                            <Spinner
                                                as="span"
                                                animation="grow"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                            />
                                            検索...
                                        </Button>
                                        :
                                        <Button size={'sm'} className="button-search" onClick={() => searchListOrg()}>
                                            検索
                                        </Button>
                                }
                            </Col>
                        </Row>
                    </Form>
                </div>
                {/* <Card className="mt-3 rounded-0">
                    <Card.Body> */}
                {currentItems?.length > 0 ? 
                    <div className="table-scroll">
                        <Table className="mt-3 rounded-0 table-organizations">
                            <thead>
                            <tr>
                                <th className="xs-column">
                                </th>
                                {
                                    columns.map((e, index) => {
                                        return (
                                            <th key={index} className={index === 3 ? "lg-column" : "xs-column"}>
                                                {e.text}
                                            </th>
                                        )
                                    })
                                }
                                <th>
                                    パスワード参照<br />
                                    <span style={{fontWeight: "normal"}}>（参照時にログが記録されます）</span>
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                currentItems.map((item, key) => {
                                    return (
                                        <tr key={key} className={
                                            item.EnableFlag
                                            /*&& compareTwoDate(new Date(), new Date(item.StartDate * 1000)) */
                                            && compareTwoDate(new Date(item.EndDate * 1000), new Date())
                                            ? 'align-middle' : 'align-middle disabled-account'}>
                                            <td className="ps-4">
                                                <Button variant="primary"
                                                        className="btn-detail"
                                                        size="sm"
                                                        onClick={() => handleShowOrgDetail(item)}
                                                        disabled={isLoading}>
                                                    詳細
                                                </Button>
                                            </td>
                                            <td>{item.AccountId}</td>
                                            <td>{item.AccountName}</td>
                                            <td>{item.OrgId}</td>
                                            <td>{item.EnvironmentType === 1 ? 'Sandbox' : '本番環境'}</td>
                                            <td>{item.LicenseNumber}</td>
                                            <td>{item.DeviceAuthNumber}</td>
                                            <td>{format(new Date(item.StartDate * 1000), "yyyy/MM/dd")}〜{format(new Date(item.EndDate * 1000), "yyyy/MM/dd")}</td>
                                            <td>{item.EnableFlag
                                            && compareTwoDate(new Date(item.EndDate * 1000), new Date())
                                                ? ''
                                                : !item.EnableFlag
                                                    ? '無効（フラグ）'
                                                    : '無効（契約期間）'}
                                            </td>
                                            <td>
                                                { item.ExportSettingTime ?
                                                    <Button onClick={() => downloadProviderFile(item)} variant="light"
                                                            className="border px-4 text-primary btn-export"
                                                            size="sm"
                                                            disabled={isLoading}>
                                                        出力
                                                    </Button>
                                                    :
                                                    <div>
                                                        <Button variant="light"
                                                                className="border px-4 text-primary btn-export btn-disabled not-allowed"
                                                                size="sm"
                                                                disabled={isLoading}
                                                                data-tooltip-id="export-tooltip"
                                                                data-tooltip-content="お客様にて端末設定ファイルが一度も出力されていない為、出力できません"
                                                                data-tooltip-place="top">
                                                            出力
                                                        </Button>
                                                        <Tooltip id="export-tooltip" />
                                                    </div>
                                                }
                                            </td>
                                            <td>{item.ExportSettingTime ? format(new Date(item.ExportSettingTime), "yyyy/MM/dd HH:mm") : ''}</td>
                                            <td>{item.EnableFlag
                                            /*&& compareTwoDate(new Date(), new Date(item.StartDate * 1000)) */
                                            && compareTwoDate(new Date(item.EndDate * 1000), new Date())
                                                ?
                                                <div onClick={() => createOrgByExistsAccount(item)}
                                                        className="text-primary btn-register">
                                                    組織登録
                                                </div>
                                                : ''}
                                            </td>
                                            <td>
                                                <div onClick={() => showOrgDevicePassword(item)}
                                                        className="text-primary btn-register">
                                                    {item.AccountName}のパスワード
                                                </div>
                                            </td>
                                        </tr>
                                    )})
                            }
                            </tbody>
                        </Table>
                    </div>
                    :
                    <h6 className="text-center pt-5 fw-bold">{!isLoading ? 'データがありません。' : '検索中・・・・'}</h6>
                }
                {
                    currentItems && currentItems.length > 0 && <nav>
                        <ul className="pagination justify-content-center">
                            <li className={currentPage === 1 ? "page-item disabled" : "page-item"}>
                                <a className="page-link" onClick={() => handleToFirstPage()}>
                                    {currentPage === 1 ?
                                        <img src={leftButton} />
                                        :
                                        <img src={leftButtonEnable} />
                                    }
                                </a>
                            </li>
                            <li className={currentPage === 1 ? "page-item disabled" : "page-item"}>
                                <a className="page-link" onClick={() => handleToPrevPage()}>
                                    {currentPage === 1 ?
                                        <IconArrow type='left' />
                                        :
                                        <IconArrow type='left' active={true} />
                                    }
                                </a>
                            </li>
                            <li className="pt-2">（{currentPage}/{totalPage}）</li>
                            <li className={currentPage === totalPage ? "page-item disabled" : "page-item"}>
                                <a className="page-link" onClick={() => handleToNextPage()}>
                                    {currentPage === totalPage ?
                                        <IconArrow type='right' />
                                        :
                                        <IconArrow type='right' active={true} />
                                    }
                                </a>
                            </li>
                            <li className={currentPage === totalPage ? "page-item disabled" : "page-item"}>
                                <a className="page-link" onClick={() => handleToLastPage()}>
                                    {currentPage === totalPage ?
                                        <img src={rightButton} />
                                        :
                                        <img src={rightButtonEnable} />
                                    }
                                </a>
                            </li>
                        </ul>
                    </nav>
                }
            </div>
        </Fragment>
    )
}