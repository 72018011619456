import {Button, Card, Form, ListGroup, Spinner} from "react-bootstrap";
import React, {Fragment, useContext, useEffect, useState} from "react";
import "react-datepicker/dist/react-datepicker.css";
import "react-datetime/css/react-datetime.css";
import 'react-loading-skeleton/dist/skeleton.css'
import {TsAdminContext} from "../../context/ts-admin";
import Modal from 'react-bootstrap/Modal';
import {toast} from "react-toastify";
import {updateOrg} from "../../api-service/org-info";
import {Tooltip} from "../../parts/tooltip";
import {CONNECT_TYPE_EX, CONNECT_TYPE_V5, useYupValidationResolver, WorkLocationInterface} from "../../common";
import * as yup from "yup";
import {useFieldArray, useForm} from "react-hook-form";
import {ErrorMessage} from "../../parts/error-message";


const validationSchema = yup.object({
    WorkLocationSetting: yup.object({
        UseIndividualSettings: yup.boolean(),
        WorkLocation: yup.string().trim().when("UseWorkLocation", (isValidate, schema) => {
            console.log("isValidate", isValidate)
            if (isValidate[0] != '0') {
                return schema.transform(value => value === '' ? undefined : value)
                    .max(10, '10文字以内で入力してくだいさい').optional()
            }
            return schema
        })
        ,
        IndividualSettings: yup.array()
            .when(["UseIndividualSettings", "UseWorkLocation"], (isValidate, schema) => {
                console.log("isValidate1", isValidate)
                if (isValidate[0] && isValidate[1] == '1') {
                    return schema.of(yup.object().shape({
                        WorkLocation: yup.string().trim()
                            .transform(value => value === '' ? undefined : value)
                            .max(10, '10文字以内で入力してくだいさい').optional(),
                        DeviceId: yup.string().trim()
                            .transform(value => value === '' ? undefined : value)
                            .required("端末IDを入力してください").matches(/^[a-zA-Z0-9]+$/, '半角英数字で入力してください')
                    }))
                }
                return schema
            })


    })
});

export function TsSettingPage() {
    const [orgInfo, setOrgInfo, userInfo] = useContext(TsAdminContext);
    const [showModalConfirm, setShowModalConfirm] = useState(false);
    const [currentSetting, setCurrentSetting] = useState(0);
    const [showModalConfirmDelete, setShowModalConfirmDelete] = useState(false);
    const [isSubmit, setIsSubmit] = useState(false);
    const useTransportationExpenseTooltipText = "しないを選択した場合はIC交通費機能にてICカードを読み込んでもエラーとなり利用出来ません。";
    const checkIdmTooltipText = "・するを選択した場合はICカード内のIDm番号がTeamSpiritに存在するかチェックを行います。\n・しないを選択した場合はICカード内のIDm番号がTeamSpiritに存在するかチェックを行いません。\n打刻時の処理速度を少しでも早めたい場合は「しない」を選択してください。"
    const resolver = useYupValidationResolver(validationSchema);
    const [connectType, setConnectType] = useState<number>();
    const {
        register,
        control,
        handleSubmit,
        reset,
        setValue,
        getValues,
        watch,
        setError,
        clearErrors,
        formState: {isSubmitting, isDirty, isSubmitted, errors}
    } = useForm({
        resolver: resolver,
        defaultValues: {
            CheckIdm: false,
            UseTransportationExpense: true,
            SoundMode: 0,
            // sendEmpCode: false
        }
    });
    const {fields: fieldWorkLocationSetting, append, remove} = useFieldArray({
        control,
        name: "WorkLocationSetting.IndividualSettings",
    });
    const watchUseIndividualSettings = watch("WorkLocationSetting.UseIndividualSettings");

    const watchUseWorkLocation = watch("WorkLocationSetting.UseWorkLocation");
    useEffect(() => {
        clearErrors(`WorkLocationSetting`);
    }, [watchUseWorkLocation]);
    const genDefaultWorkLocationSettings = (providerSetting) => {
        const workLocationSetting: WorkLocationInterface = providerSetting.WorkLocationSetting;
        if (workLocationSetting) {
            return {
                UseWorkLocation: workLocationSetting.UseWorkLocation ? '1' : '0',
                WorkLocation: providerSetting.WorkLocation || '',
                UseIndividualSettings: workLocationSetting.UseIndividualSettings,
                IndividualSettings: workLocationSetting.IndividualSettings
            };
        } else {
            return {
                UseWorkLocation: providerSetting.WorkLocation && providerSetting.WorkLocation != '' ? '1' : '0',
                WorkLocation: providerSetting.WorkLocation || "",
                UseIndividualSettings: false,
                IndividualSettings: [{
                    WorkLocation: "",
                    DeviceId: ""
                }]
            };
        }

    }
    useEffect(() => {
        if (orgInfo) {
            const currentOrg = orgInfo.currentOrg;
            setConnectType(currentOrg.ConnectType);

            console.log(genDefaultWorkLocationSettings(currentOrg.ProviderSetting), "genDefaultWorkLocationSettings(currentOrg.ProviderSetting)")
            reset({
                CheckIdm: orgInfo.currentOrg.CheckIdm ? '1' : '0',
                UseTransportationExpense: orgInfo.currentOrg.UseTransportationExpense ? '1' : '0',
                SoundMode: orgInfo.currentOrg.SoundMode.toString(),
                WorkLocationSetting: genDefaultWorkLocationSettings(currentOrg.ProviderSetting)
            })
        }

    }, [orgInfo])


    const [insertData, setInsertData] = useState<any>();
    const submitFormData = async (data) => {
        const orgInsertData = {
            CheckIdm: data.CheckIdm == 1 ? true : false,
            UseTransportationExpense: data.UseTransportationExpense == 1 ? true : false,
            SoundMode: Number(data.SoundMode),
        }
        const providerSetting = {};
        const workLocationSetting = data.WorkLocationSetting
        if (workLocationSetting.UseWorkLocation == '1') {
            if (workLocationSetting.UseIndividualSettings) {
                const arrSetting = workLocationSetting.IndividualSettings;
                const arrayUniqueByKey = arrSetting.map(item => item.DeviceId)
                    .filter((value, index, self) => self.indexOf(value) === index);
                if (arrSetting.length != arrayUniqueByKey.length) {
                    setError(`WorkLocationSetting`, {
                        message: '端末IDは重複して登録できません',
                        type: 'error'
                    });
                    return;
                } else {
                    providerSetting['WorkLocationSetting'] = {
                        UseIndividualSettings: true,
                        UseWorkLocation: !!workLocationSetting.UseWorkLocation,
                        IndividualSettings: workLocationSetting.IndividualSettings
                    }

                }
            } else {
                providerSetting['WorkLocationSetting'] = {
                    UseIndividualSettings: false,
                    UseWorkLocation: !!workLocationSetting.UseWorkLocation,
                    IndividualSettings: []
                }
            }
            providerSetting['WorkLocation'] = workLocationSetting.WorkLocation || ''

        } else {
            providerSetting['WorkLocationSetting'] = {
                UseIndividualSettings: false,
                UseWorkLocation: false,
                IndividualSettings: []
            }
            providerSetting['WorkLocation'] = ""
        }
        const newData = {
            ...orgInsertData
        }
        if (connectType == CONNECT_TYPE_V5) {
            newData['ProviderSetting'] = {
                ...providerSetting
            }
        }

        setInsertData(newData)
        /*     return;*/
        if (userInfo.isSuperAdmin) {
            setShowModalConfirm(true);
        } else {
            await updateOrgInfo(newData)
        }
    }

    const updateOrgInfo = async (data) => {
        setIsSubmit(true)
        await updateOrg(orgInfo.currentOrg.OrgId, data).then((res: any) => {
            toast.success("保存されました。");
            setOrgInfo({...orgInfo, currentOrg: res.currentOrgInfo.Attributes});
        }).catch(err => {
            toast.error("保存処理に失敗しました。再度お試しください。");
        }).finally(() => {
            if (userInfo.isSuperAdmin) {
                setShowModalConfirm(false)
            }
            setIsSubmit(false)
        })
    }

    const handleCloseModal = () => {
        setShowModalConfirm(false);
    }
    const handleCloseModalConfirmDelete = () => {
        setShowModalConfirmDelete(false);
    }

    const deleteRecord = () => {
        remove(currentSetting);
        setCurrentSetting(0);
        setShowModalConfirmDelete(false);
    }

    return (
        <Fragment>
            <Modal className="pt-5" show={showModalConfirmDelete} onHide={handleCloseModalConfirmDelete}>
                <Modal.Header closeButton>
                    <svg fill="#969492" xmlns="http://www.w3.org/2000/svg" className="info-icon me-2"
                         width="15px" height="15px" viewBox="0 0 52 52" enableBackground="new 0 0 52 52">
                        <path d="M26,2C12.7,2,2,12.7,2,26s10.7,24,24,24s24-10.7,24-24S39.3,2,26,2z M26,14.1c1.7,0,3,1.3,3,3s-1.3,3-3,3
                    s-3-1.3-3-3S24.3,14.1,26,14.1z M31,35.1c0,0.5-0.4,0.9-1,0.9h-3c-0.4,0-3,0-3,0h-2c-0.5,0-1-0.3-1-0.9v-2c0-0.5,0.4-1.1,1-1.1l0,0
                    c0.5,0,1-0.3,1-0.9v-4c0-0.5-0.4-1.1-1-1.1l0,0c-0.5,0-1-0.3-1-0.9v-2c0-0.5,0.4-1.1,1-1.1h6c0.5,0,1,0.5,1,1.1v8
                    c0,0.5,0.4,0.9,1,0.9l0,0c0.5,0,1,0.5,1,1.1V35.1z"/>
                    </svg>
                    確認
                </Modal.Header>
                <Modal.Body>この⁨⁩勤務場所コード、端末IDを削除しますか？</Modal.Body>
                <Modal.Footer className="modal-footer">
                    <Button variant="light" size="sm" className="modal-btn modal-cancel-btn"
                            onClick={handleCloseModalConfirmDelete}>
                        キャンセル
                    </Button>

                    <Button variant="danger" size="sm" className="modal-btn" onClick={deleteRecord}>
                        削除
                    </Button>

                </Modal.Footer>
            </Modal>

            <Modal className="pt-5" show={showModalConfirm} onHide={handleCloseModal}>
                <Modal.Header closeButton>

                </Modal.Header>
                <Modal.Body>更新してもよろしいでしょうか？</Modal.Body>
                <Modal.Footer className="modal-footer">
                    <Button variant="light" size="sm" className="modal-btn modal-cancel-btn" onClick={handleCloseModal}>
                        キャンセル
                    </Button>
                    {
                        isSubmit ?
                            <Button variant="primary" size="sm" className="modal-btn" disabled>
                                <Spinner
                                    as="span"
                                    animation="grow"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                />
                                登録...
                            </Button>
                            :
                            <Button variant="primary" size="sm" className="modal-btn"
                                    onClick={() => updateOrgInfo(insertData)}>
                                更新
                            </Button>
                    }
                </Modal.Footer>
            </Modal>
            <div className="ts-setting">
                <Card className="mt-3 card-container">
                    <Card.Body className="page-title">打刻連携アドオン（ICカード）</Card.Body>
                </Card>
                <Card className="mt-3 card-container">
                    {
                        orgInfo &&
                        <Form onSubmit={handleSubmit(submitFormData)}>
                            <ListGroup variant="flush">
                                <ListGroup.Item className="d-flex align-items-center">
                                    <div className="w-25 py-2 item-label">
                                        IC交通費機能利用
                                        <Tooltip text={useTransportationExpenseTooltipText} left width="560px"/>
                                    </div>
                                    <div key="inline-radio" className="w-50">
                                        <Form.Check
                                            inline
                                            label="する"
                                            {...register(`UseTransportationExpense`, {
                                                required: true,
                                            })}
                                            value={1}
                                            type="radio"
                                            id="useTransportationExpense-radio-1"
                                            className="checkbox-label me-2 radio-form-check"
                                            disabled={orgInfo.currentOrg.ConnectType == CONNECT_TYPE_EX}

                                        />
                                        <Form.Check
                                            inline
                                            label="しない"
                                            value={0}
                                            type="radio"
                                            id="useTransportationExpense-radio-2"
                                            className="checkbox-label me-2 radio-form-check"
                                            {...register(`UseTransportationExpense`, {
                                                required: true,
                                            })}
                                            disabled={orgInfo.currentOrg.ConnectType == CONNECT_TYPE_EX}

                                        />
                                    </div>
                                </ListGroup.Item>
                                <ListGroup.Item className="d-flex align-items-center ts-setting">
                                    <div className="w-25 py-2 item-label">
                                        ICカード即時チェック
                                        <Tooltip text={checkIdmTooltipText} left width="560px"/>
                                    </div>
                                    <div key="inline-radio" className="w-50">
                                        <Form.Check
                                            inline
                                            label="する"
                                            type="radio"
                                            {...register(`CheckIdm`, {
                                                required: true,
                                            })}
                                            value={1}
                                            id="checkIdm-radio-1"
                                            className="checkbox-label me-2 radio-form-check"
                                            disabled={orgInfo.currentOrg.ConnectType == CONNECT_TYPE_EX}
                                        />
                                        <Form.Check
                                            inline
                                            label="しない"
                                            {...register(`CheckIdm`, {
                                                required: true,
                                            })}
                                            value={0}
                                            type="radio"
                                            id="checkIdm-radio-2"
                                            className="checkbox-label me-2 radio-form-check"
                                            disabled={orgInfo.currentOrg.ConnectType == CONNECT_TYPE_EX}
                                        />
                                    </div>
                                </ListGroup.Item>
                                <ListGroup.Item className="d-flex align-items-center">
                                    <div className="w-25 py-2 item-label">
                                        打刻時音声モード
                                    </div>
                                    <div key="inline-radio" className="w-50">
                                        <Form.Check
                                            inline
                                            label="音声なし"
                                            {...register(`SoundMode`)}
                                            value={0}
                                            type="radio"
                                            id="soundMode-radio-1"
                                            className="checkbox-label me-2 radio-form-check"
                                        />
                                        <Form.Check
                                            inline
                                            label="音声あり（音）"
                                            {...register(`SoundMode`)}
                                            type="radio"
                                            value={1}
                                            id="soundMode-radio-2"
                                            className="checkbox-label me-2 radio-form-check"
                                        />
                                        <Form.Check
                                            inline
                                            label="音声あり（メッセージ）"
                                            type="radio"
                                            {...register(`SoundMode`)}
                                            value={2}
                                            id="soundMode-radio-3"
                                            className="checkbox-label me-2 radio-form-check"
                                        />
                                    </div>
                                </ListGroup.Item>
                                {
                                    connectType == CONNECT_TYPE_V5 &&
                                    <ListGroup.Item className="d-flex align-items-center">
                                        <div className="w-25 py-2 item-label">
                                            勤務場所設定
                                        </div>
                                        <div key="inline-radio" className="w-50">
                                            <div className="work-location">
                                                <div className="container-radio">
                                                    <Form.Check
                                                        inline
                                                        label="設定しない"
                                                        {...register(`WorkLocationSetting.UseWorkLocation`)}
                                                        type="radio"
                                                        value={0}
                                                        id="unUseWorkLocation"
                                                        className="checkbox-label me-2 radio-form-check"
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label={"設定する"}
                                                        {...register(`WorkLocationSetting.UseWorkLocation`)}
                                                        type="radio"
                                                        value={1}
                                                        id="useWorkLocation"
                                                        className="checkbox-label me-2 radio-form-check"
                                                    />
                                                </div>
                                                {
                                                    watchUseWorkLocation == 1 &&
                                                    <div className="w-100 d-flex">
                                                        <div className="radio-form-check me-2">

                                                        </div>
                                                        <div className="form-check-content w-100 ms-2">
                                                            <div
                                                                className="w-100 form-input-default mt-3 mb-3 pb-3 pt-3">
                                                                <div className="d-flex align-items-center">
                                                                    <label
                                                                        className="form-input-default-label d-flex align-content-center">
                                                                        勤務場所コード
                                                                    </label>
                                                                    <Form.Control
                                                                        className={`form-control form-control-input ms-3 me-3 ${errors[`WorkLocationSetting.WorkLocation`] ? 'error' : ''}`}
                                                                        {...register(`WorkLocationSetting.WorkLocation`)}
                                                                        size="sm"
                                                                    />
                                                                    {
                                                                        watchUseIndividualSettings &&
                                                                        <label
                                                                            className="label-default-worklocation d-flex align-content-center">デフォルト</label>}
                                                                </div>
                                                                <div className="">
                                                                    <ErrorMessage
                                                                        name={`WorkLocationSetting.WorkLocation`}
                                                                        className={"px-0 pt-1 mb-0"}
                                                                        errors={errors}/>
                                                                </div>
                                                            </div>


                                                            <div
                                                                className="w-100 form-input-checkbox d-flex align-items-center mt-3 mb-3 pb-3 pt-3">
                                                                <Form.Check className="mb-0">
                                                                    <Form.Check.Input
                                                                        type="checkbox"
                                                                        id={`form-add-setting`}
                                                                        {...register(`WorkLocationSetting.UseIndividualSettings`)}
                                                                    />

                                                                </Form.Check>

                                                                <label
                                                                    className="ms-2"
                                                                    htmlFor={`form-add-setting`}>個別の設定を追加する</label>

                                                            </div>
                                                            {
                                                                watchUseIndividualSettings &&
                                                                <div
                                                                    className="w-100 form-list-settings mt-3 mb-3 pb-3 pt-3">
                                                                    <div className="desc-setting w-100 mb-3">
                                                                        <p className="mb-0">※複数端末を登録する場合は、必ず端末IDを設定してください。 </p>
                                                                        <p className="mb-0">端末IDが登録されてない端末からの打刻はデフォルトの勤務場所コードが適用されます。</p>
                                                                        <p className="mb-0">※端末IDはPitTouch端末の端末情報表示にて確認できます。 </p>
                                                                    </div>

                                                                    <div className="form-setting-content w-100">
                                                                        {
                                                                            fieldWorkLocationSetting.map((item, index) => (
                                                                                <div className="mb-3 pb-3" key={index}>

                                                                                    <div
                                                                                        className="w-100 d-flex align-items-center">
                                                                                        <label
                                                                                            className="form-input-default-label">
                                                                                            勤務場所コード
                                                                                        </label>
                                                                                        <Form.Control
                                                                                            className={`form-control ms-2 me-2`}
                                                                                            {...register(`WorkLocationSetting.IndividualSettings.${index}.WorkLocation`)}
                                                                                            size="sm"
                                                                                        />
                                                                                        <label
                                                                                            className="form-input-default-label d-flex align-content-center">
                                                                                            端末ID
                                                                                        </label>
                                                                                        <Form.Control
                                                                                            className={`form-control ms-2 me-2`}
                                                                                            size="sm"
                                                                                            {...register(`WorkLocationSetting.IndividualSettings.${index}.DeviceId`)}
                                                                                        />
                                                                                        {
                                                                                            index != 0 &&
                                                                                            <a onClick={() => {
                                                                                                setShowModalConfirmDelete(true);
                                                                                                setCurrentSetting(index)
                                                                                            }}
                                                                                               className="btn-remove-worklocation">削除する</a>
                                                                                        }


                                                                                    </div>

                                                                                    <div
                                                                                        className="d-block">
                                                                                        <ErrorMessage
                                                                                            name={`WorkLocationSetting.IndividualSettings[${index}].DeviceId`}
                                                                                            className={"px-0 pt-1 mb-0"}
                                                                                            errors={errors}/>
                                                                                        <ErrorMessage
                                                                                            name={`WorkLocationSetting.IndividualSettings[${index}].WorkLocation`}
                                                                                            className={"px-0 pt-1 mb-0"}
                                                                                            errors={errors}/>
                                                                                    </div>

                                                                                </div>
                                                                            ))}

                                                                    </div>

                                                                    <ErrorMessage
                                                                        name={`WorkLocationSetting`}
                                                                        className={"px-0 pt-1 mt-2 mb-0"}
                                                                        errors={errors}/>

                                                                    <button type={'button'}
                                                                            onClick={() => append({
                                                                                WorkLocation: '',
                                                                                DeviceId: ''
                                                                            })}
                                                                            className={'btn btn-add-setting d-flex align-items-center'}>
                                                                        <svg width="10" height="11" viewBox="0 0 10 11"
                                                                             fill="none"
                                                                             xmlns="http://www.w3.org/2000/svg">
                                                                            <path
                                                                                d="M5.90915 6.54288H9.65933C9.74924 6.54145 9.83506 6.5051 9.89864 6.44152C9.96222 6.37794 9.99857 6.29211 10 6.20221V5.52017C9.99857 5.43026 9.96222 5.34444 9.89864 5.28085C9.83506 5.21727 9.74924 5.18092 9.65933 5.17949H5.90915C5.87886 5.18131 5.84852 5.17668 5.82014 5.16591C5.79176 5.15514 5.76599 5.13847 5.74452 5.11701C5.72306 5.09555 5.70639 5.06977 5.69562 5.0414C5.68485 5.01302 5.68022 4.98268 5.68204 4.95238V1.20151C5.68061 1.11161 5.64426 1.02578 5.58068 0.9622C5.5171 0.898618 5.43127 0.862267 5.34137 0.86084H4.65933C4.56942 0.862267 4.4836 0.898618 4.42001 0.9622C4.35643 1.02578 4.32008 1.11161 4.31865 1.20151V4.95169C4.32047 4.98199 4.31584 5.01233 4.30507 5.0407C4.2943 5.06908 4.27763 5.09485 4.25617 5.11632C4.23471 5.13778 4.20893 5.15445 4.18056 5.16522C4.15218 5.17599 4.12184 5.18062 4.09154 5.1788H0.340673C0.250648 5.18023 0.164722 5.21668 0.101122 5.28041C0.0375211 5.34414 0.00124912 5.43014 0 5.52017V6.20221C0.00142736 6.29211 0.037778 6.37794 0.10136 6.44152C0.164942 6.5051 0.250766 6.54145 0.340673 6.54288H4.09085C4.12115 6.54106 4.15149 6.54569 4.17986 6.55646C4.20824 6.56723 4.23401 6.5839 4.25548 6.60536C4.27694 6.62683 4.29361 6.6526 4.30438 6.68098C4.31515 6.70935 4.31978 6.7397 4.31796 6.76999V10.5202C4.31939 10.6101 4.35574 10.6959 4.41932 10.7595C4.4829 10.8231 4.56873 10.8594 4.65863 10.8608H5.34067C5.43058 10.8594 5.51641 10.8231 5.57999 10.7595C5.64357 10.6959 5.67992 10.6101 5.68135 10.5202V6.76999C5.67952 6.73963 5.68417 6.70923 5.69498 6.6808C5.70579 6.65237 5.72252 6.62656 5.74406 6.60509C5.7656 6.58362 5.79146 6.56696 5.81992 6.55624C5.84838 6.54551 5.8788 6.54096 5.90915 6.54288Z"
                                                                                fill="#0070D2"/>
                                                                        </svg>
                                                                        <span className="ms-2">追加</span>
                                                                    </button>
                                                                </div>
                                                            }

                                                        </div>
                                                    </div>
                                                }
                                            </div>

                                        </div>
                                    </ListGroup.Item>
                                }

                                <ListGroup.Item className="d-flex justify-content-center p-4 d-grid gap-5">
                                    <Button type="submit"
                                            className={`${isSubmit ? 'btn-export-disable' : ''}  border px-4`}
                                            size="sm"
                                            disabled={isSubmit}>
                                        {
                                            isSubmit && <Spinner
                                                as="span"
                                                animation="border"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                            />
                                        }
                                        更新
                                    </Button>
                                </ListGroup.Item>
                            </ListGroup>
                        </Form>
                    }

                </Card>
            </div>
        </Fragment>
    )

}