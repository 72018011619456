import {Fragment, useCallback, useEffect, useState} from "react";
import {Button, Card, Col, Form, Row, Spinner, Table} from "react-bootstrap";
import Modal from 'react-bootstrap/Modal';
import {useForm} from "react-hook-form";
import {useYupValidationResolver} from "../../common";
import * as yup from "yup";
import {ErrorMessage} from "../../parts/error-message";
import {createStation, getUnknownStations} from "../../api-service/station";
import {PaginationComponent} from "../../parts/pagination-component";
import {toast} from "react-toastify";
import {format} from "date-fns";

const validationSchema = yup.object({
    StationCode: yup.string().required("駅コードを入力してください。").matches(/^[a-zA-Z0-9-]+$/, '駅コードを入力してください。'),
    CompanyName: yup.string().required("鉄道会社名を入力してください。").matches(/^(?!\s+$).*/, '鉄道会社名を入力してください。'),
    LineName: yup.string().required("ラインを入力してください。").matches(/^(?!\s+$).*/, 'ラインを入力してください。'),
    StationName: yup.string().required("駅名を入力してください。").matches(/^(?!\s+$).*/, '駅名を入力してください。'),
    Memo: yup.string().trim().transform(value => value === '' ? undefined : value).matches(/^(?!\s+$).*/, '駅名を入力してください。')
});

export function UnknownStations() {
    const [isLoading, setIsLoading] = useState(false);
    const resolver = useYupValidationResolver(validationSchema);

    const {
        register,
        control,
        handleSubmit,
        setValue,
        reset,
        getValues,
        watch,
        clearErrors,
        formState: {isSubmitting, isDirty, errors, isSubmitted}
    } = useForm({
        resolver: resolver,
        defaultValues: {
            StationCode: "",
            CompanyName: "",
            LineName: "",
            StationName: "",
            Memo: "",
        },
        mode: "onBlur",
        reValidateMode: "onChange"
    });

    const [searchData, setSearchData] = useState({
        showRegisteredStation: false,
    });

    const [totalRecord, setTotalRecord] = useState<any>(0);

    const [stations, setStations] = useState<any[]>([])
    const [pageNumber, setPageNumber] = useState(1)
    const [perPage] = useState(20)
    const currentPageNumber = (pageNumber * perPage) - perPage
    const [paginatedStations, setPaginatedStations] = useState<any[]>([])
    useEffect(() => {
        const tmpStations = [...stations];
        setPaginatedStations(tmpStations.splice(currentPageNumber, perPage))
    }, [pageNumber])


    const setPage = (type) => {
        switch (type) {
            case 'start-page' : {
                setPageNumber(1);
                break;
            }
            case 'next-page' : {
                setPageNumber(pageNumber + 1)
                break;
            }
            case 'prev-page' : {
                if (pageNumber === 1) return
                setPageNumber(pageNumber - 1)
                break;
            }
            case 'end-page' : {
                console.log(Math.ceil(totalRecord / perPage),totalRecord / perPage,totalRecord , perPage)
                setPageNumber(Math.ceil(totalRecord / perPage));
                break;
            }
        }
    }

    useEffect(() => {
        getListUnknownStations();
    }, [])

    const getListUnknownStations = useCallback(async () => {
        setIsLoading(true);
        await getUnknownStations({
            ShowRegisteredStation: searchData.showRegisteredStation,
        }).then((result: any) => {
            setStations(result)
            const tmpStations = [...result];
            setPaginatedStations(tmpStations.splice(0, perPage))
            setTotalRecord(result.length)
            setIsLoading(false);
        })
    }, [searchData]);

    const [showModalAddStation, setShowModalAddStation] = useState(false);

    const columns = [
        {
            dataField: 'StationCode',
            text: '駅コード',
        }, {
            dataField: 'CompanyName',
            text: '取引先名',
        }, {
            dataField: 'OrgId',
            text: '組織ID',
        }, {
            dataField: 'IDm',
            text: 'IDm',
        }, {
            dataField: 'Timestamp',
            text: 'データ作成日',
        }
    ];

    const handleEditStation = (item) => {
        reset({
            StationCode: item.StationCode,
            CompanyName: "",
            LineName: "",
            StationName: "",
            Memo: "",
        });
        setShowModalAddStation(true);
    }

    const handleCloseModal = () => {
        setShowModalAddStation(false);
    }
    const submitFormData = async (data) => {
        await createStation(data).then(res => {
            const tmpStations = [...stations];
            tmpStations.map(e => {
                if(e.StationCode == data.StationCode) {
                    e.existStation = true;
                }
                return e;
            })
            setPaginatedStations(paginatedStations.map(e => {
                if(e.StationCode == data.StationCode) {
                    e.existStation = true;
                }
                return e;
            }))
            setStations(tmpStations)
            toast.success("駅登録に成功しました。");
            setShowModalAddStation(false);
        }).catch((errors) => {
            toast.error(errors);

        })
    }
    return (
        <Fragment>
            <Modal className="pt-5" show={showModalAddStation} onHide={handleCloseModal}>
                <Form onSubmit={handleSubmit(submitFormData)}>
                    <Modal.Header closeButton>
                        駅登録
                    </Modal.Header>
                    <Modal.Body>

                        <div className="d-flex pt-3">
                            <Form.Label className="mt-2 me-2 modal-form-label">
                                駅コード
                            </Form.Label>
                            <p className="py-2 item-label mb-0 modal-form-control">{getValues('StationCode')}</p>
                        </div>
                        <div className="d-flex pt-3">
                            <Form.Label className="mt-2 me-2 modal-form-label">
                                鉄道会社名
                            </Form.Label>
                            <div className="d-block w-100">
                                <Form.Control className="modal-form-control" size="sm"
                                    {...register(`CompanyName`)}
                                />
                                {isSubmitted &&
                                    <ErrorMessage name='CompanyName' className={'px-0 py-0 mb-0'} errors={errors}/>}
                            </div>
                        </div>
                        <div className="d-flex pt-3">
                            <Form.Label className="mt-2 me-2 modal-form-label">
                                ライン
                            </Form.Label>
                            <div className="d-block w-100">
                                <Form.Control className="modal-form-control" size="sm"
                                    {...register(`LineName`)}
                                />
                                {isSubmitted &&
                                    <ErrorMessage name='LineName' className={'px-0 py-0 mb-0'} errors={errors}/>}
                            </div>
                        </div>
                        <div className="d-flex pt-3">
                            <Form.Label className="mt-2 me-2 modal-form-label">
                                駅名
                            </Form.Label>
                            <div className="d-block w-100">
                                <Form.Control className="modal-form-control" size="sm"
                                    {...register(`StationName`)}
                                />
                                {isSubmitted &&
                                    <ErrorMessage name='StationName' className={'px-0 py-0 mb-0'} errors={errors}/>}
                            </div>

                        </div>

                        <div className="d-flex pt-3">
                            <Form.Label className="mt-2 me-2 modal-form-label">
                                メモ
                            </Form.Label>
                            <div className="d-block w-100">
                                <Form.Control className="modal-form-control" size="sm"
                                    {...register(`Memo`)}
                                />
                                {isSubmitted &&
                                    <ErrorMessage name='Memo' className={'px-0 py-0 mb-0'} errors={errors}/>}
                            </div>

                        </div>

                    </Modal.Body>
                    <Modal.Footer className="modal-footer">
                        <Button variant="light" size="sm" className="modal-btn modal-cancel-btn"
                                onClick={handleCloseModal}>
                            キャンセル
                        </Button>
                        <Button variant="primary" size="sm" type="submit" className="modal-btn">
                            登録
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
            <div className="stations">
                <div className="mt-3">
                    <Card className="mt-3 card-container">
                        <Card.Body className="page-title">未登録駅</Card.Body>
                    </Card>
                    <Form>
                    <Row className="d-flex align-items-center p-3">
                        <Col xs={12} md={4} lg="auto" className="d-flex align-items-center col-auto pt-2">
                            <Form.Label className="mt-2 me-2 station-form-label">
                                登録済みの駅
                            </Form.Label>
                            <input 
                                className="station-form-checkbox"
                                type="checkbox" 
                                checked={searchData.showRegisteredStation}
                                id="showDisableAccounts" 
                                onChange={e => setSearchData({ ...searchData, showRegisteredStation: e.target.checked })}/>
                            <label className="station-form-label ps-1">含む</label>
                        </Col>
                        <Col xs={4} md="auto" lg="auto" className="align-items-center pt-2">
                            {
                                isLoading ?
                                    <Button size={'sm'} className="button-search" disabled>
                                        <Spinner
                                            as="span"
                                            animation="grow"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                        />
                                        検索...
                                    </Button>
                                    :
                                    <Button size={'sm'} className="button-search" onClick={() => getListUnknownStations()}>
                                        検索
                                    </Button>
                            }
                        </Col>
                    </Row>
                </Form>

                    {paginatedStations?.length > 0 ?
                        <div className="table-scroll">
                            <Table className="mt-3 rounded-0 table-stations">
                                <thead>
                                <tr>
                                    <th className="xs-column">
                                    </th>
                                    {
                                        columns.map((e, index) => {
                                            return (
                                                <th key={index} className={index === 3 ? "lg-column" : "xs-column"}>
                                                    {e.text}
                                                </th>
                                            )
                                        })
                                    }
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    paginatedStations.map((item, key) => {
                                        return (
                                            <tr key={key} className={`${item.existStation ? 'bg-exists-station' : ''}`}>
                                                <td>
                                                <Button variant="primary"
                                                    className={`${item.existStation ? 'disabled btn-detail-disabled' : 'btn-detail'}`}
                                                    size="sm"
                                                    onClick={() => handleEditStation(item)}>
                                                    駅登録
                                                </Button>
                                                </td>
                                                <td>{item.StationCode}</td>
                                                <td>{item.CompanyName}</td>
                                                <td>{item.OrgId}</td>
                                                <td>{item.IDm}</td>
                                                <td>{format(new Date(item.Timestamp), "yyyy/MM/dd HH:mm")}</td>
                                               {/* <td>{item.Timestamp.substr(0, 10).replaceAll("-", "/")}</td>*/}
                                            </tr>
                                        )
                                    })
                                }
                                </tbody>
                            </Table>
                        </div>
                        :
                        <h6 className="text-center pt-5 fw-bold">{!isLoading ? 'データがありません。' : '検索中・・・・'}</h6>
                    }

                    {
                        stations && stations.length > 0 && <PaginationComponent
                            totalPage={Math.ceil(totalRecord / perPage)}
                            handlePage={setPage}
                            currentPage={pageNumber}
                        />
                    }

                </div>
            </div>
        </Fragment>
    )
}