import { Fragment, useCallback, useEffect, useState } from "react";
import { Button, Card, Col, Form, Row, Spinner, Table } from "react-bootstrap";
import Modal from 'react-bootstrap/Modal';
import { useYupValidationResolver } from "../../common";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { getStations, createStation, updateStation } from "../../api-service/station";
import { PaginationComponent } from "../../parts/pagination-component";
import {format} from 'date-fns';
import { toast } from "react-toastify";
import { ErrorMessage } from "../../parts/error-message";
import { CSVLink } from "react-csv";

const validationSchema = yup.object({
    StationCode: yup.string().required("駅コードを入力してください。").matches(/^[a-zA-Z0-9-]+$/, '駅コードを入力してください。'),
    CompanyName: yup.string().required("鉄道会社名を入力してください。").matches(/^(?!\s+$).*/, '鉄道会社名を入力してください。'),
    LineName: yup.string().required("ラインを入力してください。").matches(/^(?!\s+$).*/, 'ラインを入力してください。'),
    StationName: yup.string().required("駅名を入力してください。").matches(/^(?!\s+$).*/, '駅名を入力してください。'),
    Memo: yup.string().trim().transform(value => value === '' ? undefined : value).matches(/^(?!\s+$).*/, '駅名を入力してください。')
});

export function Stations() {
    const [isLoading, setIsLoading] = useState(false);
    const resolver = useYupValidationResolver(validationSchema);
    const [isRegisterStation, setIsRegisterStation] = useState(false);
    const [isUpdateStation, setIsUpdateStation] = useState(false);
    
    const {
        register,
        control,
        handleSubmit,
        setValue,
        reset,
        getValues,
        watch,
        clearErrors,
        formState: {isSubmitting, isDirty, errors, isSubmitted}
    } = useForm({
        resolver: resolver,
        defaultValues: {
            StationCode: "",
            CompanyName: "",
            LineName: "",
            StationName: "",
            Memo: "",
        },
        mode: "onBlur",
        reValidateMode: "onChange"

    });

    const [currentItems, setCurrentItems] = useState<any[]>([]);
    const [totalRecord, setTotalRecord] = useState<any>(0);
    const [pageNumber, setPageNumber] = useState(1)
    const [perPage] = useState(20)
    const currentPageNumber = (pageNumber * perPage) - perPage;
    const [paginatedStations, setPaginatedStations] = useState<any[]>([])
    const [showModalAddStation, setShowModalAddStation] = useState(false);

    const [searchData, setSearchData] = useState({
        stationCode: "",
        companyName: "",
        lineName: "",
        staionName: "",
        searchType: "0",
        memo: "",
    });

    const headers = [
        { label: "StationCode", key: "StationCode" },
        { label: "CompanyName", key: "CompanyName" },
        { label: "LineName", key: "LineName" },
        { label: "StationName", key: "StationName" },
        { label: "Memo", key: "Memo" },
    ];

    useEffect(() => {
        const tmpStations = [...currentItems];
        setPaginatedStations(tmpStations.splice(currentPageNumber, perPage))
    }, [pageNumber])

    const setPage = (type) => {
        switch (type) {
            case 'start-page' : {
                setPageNumber(1);
                break;
            }
            case 'next-page' : {
                setPageNumber(pageNumber + 1)
                break;
            }
            case 'prev-page' : {
                if (pageNumber === 1) return
                setPageNumber(pageNumber - 1)
                break;
            }
            case 'end-page' : {
                setPageNumber(Math.ceil(totalRecord / perPage));
                break;
            }
        }
    }

    useEffect(() => {
        getListStations();
    }, [])

    const getListStations = useCallback(async () => {
        console.log(searchData)
        setIsLoading(true);
        await getStations({
            StationCode: searchData.stationCode,
            CompanyName: searchData.companyName,
            LineName: searchData.lineName,
            StationName: searchData.staionName,
            SearchType: searchData.searchType,
            Memo: searchData.memo,
        }).then((result: any) => {
            setCurrentItems(result);
            console.log(currentItems[200]);
            const tmpStations = [...result];
            setPaginatedStations(tmpStations.splice(0, perPage));
            setTotalRecord(result.length);
            setPageNumber(1);
            setIsLoading(false);
        })
    }, [searchData]);

    const columns = [
        {
            dataField: 'station_code',
            width:"7%",
            text: '駅コード',
        }, {
            dataField: 'company_name',
            width:"15%",
            text: '鉄道会社名',
        }, {
            dataField: 'line_name',
            width:"15%",
            text: 'ライン',
        }, {
            dataField: 'station_name',
            width:"15%",
            text: '駅名',
        }, {
            dataField: 'memo',
            width:"28%",
            text: 'メモ',
        }, {
            dataField: 'created_date',
            width:"10%",
            text: 'データ作成日',
        }, {
            dataField: 'last_modified_date',
            width:"10%",
            text: 'データ更新日',
        }
    ];

    const handleEditStation = (item) => {
        reset({
            StationCode: item.StationCode,
            CompanyName: item.CompanyName,
            LineName: item.LineName,
            StationName: item.StationName,
            Memo: item.Memo,
        });
        setIsUpdateStation(true);
        setShowModalAddStation(true);
    }

    const handleAddStation = () => {
        reset({
            StationCode: "",
            CompanyName: "",
            LineName: "",
            StationName: "",
            Memo: "",
        });
        setIsUpdateStation(false);
        setShowModalAddStation(true);
    }

    const handleCloseModal = () => {
        setShowModalAddStation(false);
    }

    const submitFormData = async (data) => {
        setIsRegisterStation(true);
        if (isUpdateStation) {
            setIsLoading(true);
            await updateStation(data).then((res : any) => {
                const tmpStations = [...currentItems];
                tmpStations.map(e => {
                    if(e.StationCode == data.StationCode) {
                        e.existStation = true;
                    }
                    return e;
                })
                setPaginatedStations(paginatedStations.map(e => {
                    if(e.StationCode == data.StationCode) {
                        e.CompanyName = data.CompanyName;
                        e.LineName = data.LineName;
                        e.StationName = data.StationName;
                        e.Memo = data.Memo;
                        e.LastModifiedDate = new Date(res.LastModifiedDate).getTime()/1000;
                    }
                    return e;
                }))
                setCurrentItems(tmpStations);
                toast.success("駅登録に成功しました。");
            }).catch(errors => {
                toast.error(errors);
            }).finally(() => {
                setIsRegisterStation(false);
                setShowModalAddStation(false);
                setIsLoading(false);
            })
        } else {
            data = {...data, isCreate: true}
            await createStation(data).then((res: any) => {
                const tmpStations = [...currentItems];
                tmpStations.unshift({
                    StationCode: res.StationCode,
                    CompanyName: res.CompanyName,
                    LineName: res.LineName,
                    StationName: res.StationName,
                    Memo: res.Memo,
                    CreatedDate: new Date(res.CreatedDate).getTime(),
                    LastModifiedDate: new Date(res.LastModifiedDate).getTime(),
                })
                setCurrentItems(tmpStations);
                const newPageItem = [...tmpStations];
                setPaginatedStations(newPageItem.splice(0, perPage));
                setTotalRecord(tmpStations.length);
                setPageNumber(1);
                toast.success("駅登録に成功しました。");
            }).catch(errors => {
                toast.error(errors);
            }).finally(() => {
                setIsRegisterStation(false);
                setShowModalAddStation(false);
            })
        }
    }

    return (
        <Fragment>
            <Modal className="pt-5" show={showModalAddStation} onHide={handleCloseModal}>
                <Form onSubmit={handleSubmit(submitFormData)}>
                    <Modal.Header closeButton>
                        駅コード登録
                    </Modal.Header>
                    <Modal.Body>    
                        <div className="d-flex pt-3">
                            <Form.Label className="mt-2 me-2 modal-form-label">
                                駅コード
                            </Form.Label>
                            <div className="d-block w-100">
                                {isUpdateStation ?
                                    <p className="py-2 item-label mb-0 modal-form-control">{getValues('StationCode')}</p>
                                    :
                                    <Form.Control className="modal-form-control" size="sm"
                                        {...register(`StationCode`)}
                                    />
                                }
                                {isSubmitted &&
                                    <ErrorMessage name='StationCode' className={'px-0 pt-1 mb-0'} errors={errors}/>}
                            </div>
                        </div>
                        <div className="d-flex pt-3">
                            <Form.Label className="mt-2 me-2 modal-form-label">
                                鉄道会社名
                            </Form.Label>
                            <div className="d-block w-100">
                                <Form.Control className="modal-form-control" size="sm"
                                    {...register(`CompanyName`)}
                                />
                                {isSubmitted &&
                                    <ErrorMessage name='CompanyName' className={'px-0 pt-1 mb-0'} errors={errors}/>}
                            </div>
                        </div>
                        <div className="d-flex pt-3">
                            <Form.Label className="mt-2 me-2 modal-form-label">
                                ライン
                            </Form.Label>
                            <div className="d-block w-100">
                                <Form.Control className="modal-form-control" size="sm"
                                    {...register(`LineName`)}
                                />
                                {isSubmitted &&
                                    <ErrorMessage name='LineName' className={'px-0 pt-1 mb-0'} errors={errors}/>}
                            </div>
                        </div>
                        <div className="d-flex pt-3">
                            <Form.Label className="mt-2 me-2 modal-form-label">
                                駅名
                            </Form.Label>
                            <div className="d-block w-100">
                                <Form.Control className="modal-form-control" size="sm"
                                    {...register(`StationName`)}
                                />
                                {isSubmitted &&
                                    <ErrorMessage name='StationName' className={'px-0 pt-1 mb-0'} errors={errors}/>}
                            </div>
                        </div>

                        <div className="d-flex pt-3">
                            <Form.Label className="mt-2 me-2 modal-form-label">
                                メモ
                            </Form.Label>
                            <div className="d-block w-100">
                                <Form.Control className="modal-form-control" size="sm"
                                    {...register(`Memo`)}
                                />
                                {isSubmitted &&
                                    <ErrorMessage name='Memo' className={'px-0 pt-1 mb-0'} errors={errors}/>}
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer className="modal-footer">
                        <Button variant="light" size="sm" className="modal-btn modal-cancel-btn" onClick={handleCloseModal}>
                            キャンセル
                        </Button>
                        {
                            isRegisterStation ?
                                <Button variant="primary" size="sm" type="submit" className="modal-btn" disabled>
                                    <Spinner
                                        as="span"
                                        animation="grow"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    />
                                    登録...
                                </Button>
                                :
                                <Button variant="primary" size="sm" type="submit" className="modal-btn">
                                    登録
                                </Button>
                        }
                    </Modal.Footer>
                </Form>
            </Modal>
            <div className="stations">
                <div className="mt-3">
                <Card className="mt-3 card-container">
                    <Card.Body className="page-title">駅名マスタ</Card.Body>
                </Card>
                <Form>
                    <Row className="d-flex align-items-center p-3">
                        <Col xs={12} md={7} lg={5} className="d-flex align-items-center col-2 pt-2">
                            <Form.Label className="mt-2 me-2 station-form-label">
                                駅コード
                            </Form.Label>
                            <Form.Control className="station-form-control"
                                onChange={e => setSearchData({ ...searchData, stationCode: e.target.value })}>
                            </Form.Control>
                        </Col>
                        <Col xs={12} md={7} lg={5} className="d-flex align-items-center col-2 pt-2">
                            <Form.Label className="mt-2 me-2 station-form-label">
                                鉄道会社名
                            </Form.Label>
                            <Form.Control className="station-form-control"
                                onChange={e => setSearchData({ ...searchData, companyName: e.target.value })}>
                            </Form.Control>
                        </Col>
                        <Col xs={12} md={7} lg={5} className="d-flex align-items-center col-2 pt-2">
                            <Form.Label className="mt-2 me-2 station-form-label">
                                ライン
                            </Form.Label>
                            <Form.Control className="station-form-control"
                                onChange={e => setSearchData({ ...searchData, lineName: e.target.value })}>
                            </Form.Control>
                        </Col>
                        <Col xs={12} md={7} lg={5} className="d-flex align-items-center col-2 pt-2">
                            <Form.Label className="mt-2 me-2 station-form-label">
                                駅名
                            </Form.Label>
                            <Form.Control className="station-form-control"
                                onChange={(e => setSearchData({ ...searchData, staionName: e.target.value }))}>
                            </Form.Control>
                        </Col>
                        <Col xs={12} md={7} lg={5} className="d-flex align-items-center col-2 pt-2">
                            <Form.Label className="mt-2 me-2 station-form-label">
                                メモ
                            </Form.Label>
                            <Form.Control className="station-form-control"
                                onChange={(e => setSearchData({ ...searchData, memo: e.target.value }))}>
                            </Form.Control>
                        </Col>
                        <Col xs={12} md={7} lg="auto" className="d-flex align-items-center col-2 pt-2">
                            <Form.Label className="mt-2 me-2 station-form-label" htmlFor="stationSearchType">
                                組織種別
                            </Form.Label>
                            <Form.Check
                                value="0"
                                name="stationSearchType"
                                type="radio"
                                id="stationSearchType1"
                                label="あいまい"
                                className="station-form-checkbox pe-2"
                                checked={searchData?.searchType === "0"}
                                onChange={e => setSearchData({ ...searchData, searchType: e.target.value })}
                            />
                            <Form.Check
                                value="1"
                                name="stationSearchType"
                                type="radio"
                                id="stationSearchType2"
                                label="完全一致"
                                className="station-form-checkbox pe-2"
                                checked={searchData?.searchType === "1"}
                                onChange={e => setSearchData({ ...searchData, searchType: e.target.value })}
                            />
                        </Col>
                        <Col xs={4} md="auto" lg="auto" className="align-items-center pt-2">
                            {
                                isLoading ?
                                    <Button size={'sm'} className="button-search" disabled>
                                        <Spinner
                                            as="span"
                                            animation="grow"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                        />
                                        検索...
                                    </Button>
                                    :
                                    <Button size={'sm'} className="button-search" onClick={() => getListStations()}>
                                        検索
                                    </Button>
                            }
                        </Col>
                    </Row>
                </Form>
                <div className="d-flex justify-content-between">
                    <div className="add-station-btn d-flex align-items-center" onClick={handleAddStation}>
                        <svg className="pe-1" fill="#000000" width="20px" height="20px" viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg">
                            <path className="plus-icon" fill="#B0B0B0" d="M15.5 29.5c-7.18 0-13-5.82-13-13s5.82-13 13-13 13 5.82 13 13-5.82 13-13 13zM21.938 15.938c0-0.552-0.448-1-1-1h-4v-4c0-0.552-0.447-1-1-1h-1c-0.553 0-1 0.448-1 1v4h-4c-0.553 0-1 0.448-1 1v1c0 0.553 0.447 1 1 1h4v4c0 0.553 0.447 1 1 1h1c0.553 0 1-0.447 1-1v-4h4c0.552 0 1-0.447 1-1v-1z"></path>
                        </svg>
                        新規追加
                    </div>
                    <CSVLink style={{textDecoration: 'none'}} data={currentItems} headers={headers} filename={`PittouchStation-${format(new Date(), "yyyy/MM/dd HH:mm")}.csv`}>
                        <Button className="btn-export-csv" variant="outline-primary">
                            <svg fill="#0176D3" width="18px" height="18px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" id="download" className="download-icon pe-1">
                                <path d="M20,15a1,1,0,0,0-1,1V20a.22.22,0,0,1-.15.05H5.14C5.06,20,5,20,5,20V16a1,1,0,0,0-2,0v4a2.08,2.08,0,0,0,2.14,2H18.86A2.08,2.08,0,0,0,21,20V16A1,1,0,0,0,20,15Z" ></path>
                                <path d="M11.29,16.71a1,1,0,0,0,.33.21.94.94,0,0,0,.76,0,1,1,0,0,0,.33-.21l4-4a1,1,0,0,0-1.42-1.42L13,13.59V3a1,1,0,0,0-2,0V13.59l-2.29-2.3a1,1,0,1,0-1.42,1.42Z" ></path>
                        </svg>CSVダウンロード
                        </Button>
                    </CSVLink>
                </div>
                {paginatedStations?.length > 0 ? 
                    <div className="table-scroll">
                        <Table className="mt-3 rounded-0 table-stations">
                            <thead>
                                <tr>
                                    {
                                        columns.map((e, index) => {
                                            return (
                                                <th key={index} style={{width: e.width}}>
                                                    {e.text}
                                                </th>
                                            )
                                        })
                                    }
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    paginatedStations.map((item, key) => {
                                    return (
                                        <tr key={key}>
                                            <td>
                                                <div onClick={() => handleEditStation(item)}
                                                    className="text-primary btn-register">
                                                    {item.StationCode}
                                                </div>
                                            </td>
                                            <td>{item.CompanyName}</td>
                                            <td>{item.LineName}</td>
                                            <td>{item.StationName}</td>
                                            <td>{item.Memo}</td>
                                            <td>{item.CreatedDate ? format(new Date(item.CreatedDate*1000), "yyyy/MM/dd HH:mm") : ''}</td>
                                            <td>{item.LastModifiedDate ? format(new Date(item.LastModifiedDate*1000), "yyyy/MM/dd HH:mm") : ''}</td>
                                        </tr>
                                    )})
                                }
                            </tbody>
                        </Table>
                    </div>
                    :
                    <h6 className="text-center pt-5 fw-bold">{!isLoading ? 'データがありません。' : '検索中・・・・'}</h6>
                }
                {
                    currentItems && currentItems.length > 0 && <PaginationComponent
                        totalPage={Math.ceil(totalRecord / perPage)}
                        handlePage={setPage}
                        currentPage={pageNumber}
                    />
                }
                </div>
            </div>
        </Fragment>
    )
}