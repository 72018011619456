import {Navigate, useLocation, useNavigate} from "react-router-dom";
import {useAuth} from "../../provider";
import {Button, Card, Col, Container, Form, Row} from "react-bootstrap";
import {useEffect, useState} from "react";
import {apiGet} from "../../api-service/api";
import {toast} from "react-toastify";
import {ENVIRONMENT_TYPE_PRODUCT, ENVIRONMENT_TYPE_SANDBOX} from "../../common";

export function CallbackLoginSfPage() {
    const query = new URLSearchParams(window.location.search);
    const navigate = useNavigate();

    useEffect(() => {
        if (query.get('code')) {
            const reqBody: any = {
                code: query.get('code'),
                state: query.get('state'),
                redirect_uri: process.env.REACT_APP_SF_REDIRECT_URL
            };
            const envLogin = localStorage.getItem('environment-type') || ENVIRONMENT_TYPE_PRODUCT;
            if (envLogin == "" + ENVIRONMENT_TYPE_SANDBOX) {
                reqBody.env = envLogin;
            }
            if (localStorage.getItem('user-info')) {
                const userInfo: any = JSON.parse(localStorage.getItem('user-info') || "")
                reqBody.organizationId = userInfo?.organizationId;
                reqBody.userId = userInfo?.id;
            }
            apiGet('/get-sf-token', reqBody).then((rs: any) => {
                if (rs.success) {
                    if (rs.state === 'token') {
                        localStorage.setItem('result-callback-sf', JSON.stringify(rs));
                    } else {
                        localStorage.setItem('access-token', rs.accessToken);
                        localStorage.setItem('user-info', JSON.stringify(rs.userInfo));
                        window.location.href = rs.state === 'admin' ? '/admin-console' : '/';
                    }
                } else {
                    toast.error("Get token salesforce fail!!");
                }
            }).catch((error) => {

                if (localStorage.getItem('access-token')) {
                    localStorage.setItem('result-callback-sf', JSON.stringify(error));
                } else {
                    toast.error(error.message.error);
                    navigate(`/${query.get('state')}/login`);

          /*          setTimeout(() => {
                        window.location.href = `${query.get('state')}/login`;
                    }, 1500);*/
                }
            });
        } else {
            //window.location.href = "/";
        }
    }, []);

    return (
        <Container>

        </Container>
    );
}