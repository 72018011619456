import {Button, Card, Form, Spinner, Tab, Table, Tabs} from "react-bootstrap";
import {Fragment, useContext, useEffect, useState} from "react";
import "react-datepicker/dist/react-datepicker.css";
import "react-datetime/css/react-datetime.css";
import "react-loading-skeleton/dist/skeleton.css"
import {TsAdminContext} from "../../context/ts-admin";
import {ExSettingTabCompany} from "./tab-company";
import {ExSettingTabPattern} from "./tab-pattern";
import Modal from "react-bootstrap/Modal";
import {ErrorMessage} from "../../parts/error-message";
import {createStation, updateStation} from "../../api-service/station";
import {toast} from "react-toastify";
import {useFieldArray, useForm, useWatch} from "react-hook-form";
import * as yup from "yup";
import {selectBoxStatusCodeButtonCase2, useYupValidationResolver} from "../../common";
import {createNewPattern, updateDataCompany, getCompanies, getPatterns} from "../../api-service/pittouch-ex";

const validationSchema = yup.object({
    CompanyCode: yup.string().required("会社コードを入力してください。").matches(/^[a-zA-Z0-9-]+$/, "会社コードを入力してください。"),
    DeviceIdArray: yup.lazy(() => yup.array().of(
        yup.object({
            id: yup.string().required("端末IDを入力してください。").matches(/^[a-zA-Z0-9-]+$/, "端末IDを入力してください。"),
        })
    ).required()),
});

const mapDataPattern = (patterns) => {
    const results = patterns.map((e: any) => {
        e.pattern_name = e.Name;
        e.id = e.BentoPatternId;
        const patternContent = e.BentoPattern;
        e.pattern_1 = patternContent[0];
        e.pattern_2 = patternContent[1];
        e.pattern_3 = patternContent[2];
        e.pattern_4 = patternContent[3];
        return e;
    })
    return results;
}

const mapDataCompany = (companies, patterns) => {
    const results = companies.map((com: any) => {
        com.device_id = com.DeviceId.toString();
        let currentPattern = patterns.find(e => e.id === com.PatternId);
        com.pattern = currentPattern;
        com.company_id = com.CompanyCode;

        return com;
    })
    return results;
}

const defaultValueFormCompany = {
    CompanyCode: "",
    DeviceIdArray: [{id: ""}],
    BentoPatternId: "0"
};

const checkUniqueDevice = (device) => {
    const uniqueDevice = device.filter(
        (value, index, self) => self.findIndex((m) => m.id === value.id) === index,
    );
    return device.length == uniqueDevice.length
}

export function PittouchExSetting() {
    const [orgInfo] = useContext(TsAdminContext);
    const resolver = useYupValidationResolver(validationSchema);
    const [key, setKey] = useState<any>("company");
    const [showModalAddCompany, setShowModalAddCompany] = useState(false);
    const [isShowModalPattern, setIsShowModalPattern] = useState(false);
    const [isSubmitFormCreateCompany, setIsSubmitFormCreateCompany] = useState(false);
    const [actionEditForm, setActionEditForm] = useState(false);
    const [initFlag, setInitFlag] = useState(false);
    const [patterns, setPatterns] = useState<any>([]);
    const [companies, setCompanies] = useState<any>([]);
    const [currentPattern, setCurrentPattern] = useState<any>();
    const [errorDevice, setErrorDevice] = useState<any>(null);
    const {
        register,
        control,
        handleSubmit,
        setValue,
        reset,
        getValues,
        watch,
        clearErrors,
        formState: {isSubmitting, isDirty, errors, isSubmitted}
    } = useForm({
        resolver: resolver,
        mode: "onChange",
        defaultValues: defaultValueFormCompany,
    });
    const {fields, append, remove} = useFieldArray({
        control,
        name: "DeviceIdArray",
    });
    const [isLoading, setIsLoading] = useState(false);
    const createCompany = async (data) => {
        setErrorDevice(null);
        if (!checkUniqueDevice(data.DeviceIdArray)) {
            setErrorDevice("端末IDは重複しない値を入力してください。");
            return;
        }
        setIsSubmitFormCreateCompany(true);
        const newData = {
            Type: data.Type ? data.Type : 0, // 1 Edit | 0 Create
            BentoPatternId: data.BentoPatternId,
            CompanyCode: data.CompanyCode,
            DeviceIdArray: data.DeviceIdArray.map(e => {
                return e.id
            })
        }
        await updateDataCompany(newData)
            .then((res: any) => {
                try {
                    if (res.error) {
                        setErrorDevice("既に登録されている端末IDは登録できません。");
                    } else {
                        if (newData.Type === 0) {
                            const tmpCompanies = [...mapDataCompany(res, patterns), ...companies];
                            setCompanies(tmpCompanies);
                        } else {
                            const tmpCompanies = [...companies];
                            const resData = mapDataCompany(res, patterns);
                            const newCompaniesData = tmpCompanies.map(e => {
                                if (e.CompanyCode == resData[0].CompanyCode) {
                                    return resData[0];

                                }
                                return e;
                            })

                            setCompanies(newCompaniesData);
                        }
                        handleCloseModal();
                    }
                } catch (e) {
                    console.error(e)
                }
            })
            .catch(e => toast.error(e))
            .finally(() => {
                setIsSubmitFormCreateCompany(false)
            })


    }

    useEffect(() => {
        setIsLoading(true);
        if (!initFlag && orgInfo) {
            let patternTemp;
            getPatterns({
                TargetOrg: `${localStorage.getItem('target-org') || orgInfo?.currentOrg?.OrgId}`,
            }).then((res: any) => {
                patternTemp = mapDataPattern(res);
                setPatterns(mapDataPattern(res));
                setIsLoading(false);
            }).then(response => {
                getCompanies({
                    TargetOrg: `${localStorage.getItem('target-org') || orgInfo?.currentOrg?.OrgId}`,
                }).then((res1: any) => {
                    setCompanies(mapDataCompany(res1, patternTemp));
                    setIsLoading(false);
                })
            }).finally(() => {
                setInitFlag(true);
                setIsLoading(false);
            });
        }
    }, [orgInfo, initFlag])

    const handleCloseModal = () => {
        setShowModalAddCompany(false);
        reset(defaultValueFormCompany);
        setErrorDevice(null);
        setActionEditForm(false);
    }

    const onCallbackEdit = (item) => {
        setShowModalAddCompany(true);
        setActionEditForm(true);
        const listDevice: any = [];
        if (Array.isArray(item.DeviceId) && item.DeviceId.length > 0) {
            for (let i = 0; i < item.DeviceId.length; i++) {
                listDevice.push({
                    id: item.DeviceId[i]
                });
            }
        }
        reset({
            Type: 1,
            CompanyCode: item.CompanyCode,
            DeviceIdArray: listDevice,
            BentoPatternId: item.PatternId
        });
    }

    const onCallbackEditPattern = (item) => {
        const tmpPattern = patterns.find(e => e.id == item.id);
        if (tmpPattern) {
            setCurrentPattern(tmpPattern);
            setIsShowModalPattern(true);
        }
    }
    return (
        <Fragment>
            <Modal className="pt-5 form-company" backdrop='static' show={showModalAddCompany} onHide={handleCloseModal}>
                <Form onSubmit={handleSubmit(createCompany)}>
                    <Modal.Header closeButton>
                        {actionEditForm ? '会社（編集）' : '会社'}

                    </Modal.Header>
                    <Modal.Body>
                        <div className="d-flex pt-3">
                            <Form.Label className="mt-2 me-2 w-25 modal-form-label">
                                <span className="required">*</span>会社コード
                            </Form.Label>
                            <div className="d-block w-75">
                                <Form.Control className="modal-form-control" size="sm"
                                              disabled={actionEditForm}
                                              {...register(`CompanyCode`)}
                                />
                                {isSubmitted &&
                                    <ErrorMessage name="CompanyCode" className={"px-0 pt-1 mb-0"} errors={errors}/>}
                            </div>
                        </div>
                        <div className="d-flex pt-3">
                            <Form.Label className="mt-2 w-25 me-2 modal-form-label">
                                <span className="required">*</span>端末ID
                            </Form.Label>
                            <div className="d-block w-75">
                                {fields.map((field, index) => {
                                    return (
                                        <Fragment key={index}>
                                            <div className={`form-company-array mb-3`}>
                                                <div className={`${index == 0 ? "d-block" : "d-flex"} `}>
                                                    <Form.Control className="w-75 modal-form-control" size="sm"
                                                                  {...register(`DeviceIdArray[${index}].id` as const, {required: "required"})}
                                                    />
                                                    {
                                                        index > 0 && <div className="w-25 position-relative">
                                                            <a className="btn-remove-device-id"
                                                               onClick={() =>
                                                                   remove(index)
                                                               }>
                                                                削除
                                                            </a>
                                                        </div>
                                                    }
                                                </div>

                                                <div className="w-100 d-block">
                                                    {
                                                        errors[`DeviceIdArray[${index}].id`] &&
                                                        <div className={`d-flex align-items-center`}>
                                                            <p className={`error`}>{`${errors[`DeviceIdArray[${index}].id`]?.message}`}</p>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        </Fragment>
                                    )
                                })}
                                {
                                    errorDevice && <div className={`d-flex align-items-center`}>
                                        <p className={`error pt-0`}>{errorDevice}</p>
                                    </div>
                                }
                                <a
                                    className="btn-add-device-id"
                                    onClick={() => append({
                                        id: ""
                                    })}
                                >
                                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="11" viewBox="0 0 10 11"
                                         fill="none">
                                        <path
                                            d="M5.90915 6.18204H9.65933C9.74924 6.18061 9.83506 6.14426 9.89864 6.08068C9.96222 6.0171 9.99857 5.93127 10 5.84137V5.15933C9.99857 5.06942 9.96222 4.9836 9.89864 4.92001C9.83506 4.85643 9.74924 4.82008 9.65933 4.81865H5.90915C5.87886 4.82047 5.84852 4.81584 5.82014 4.80507C5.79176 4.7943 5.76599 4.77763 5.74452 4.75617C5.72306 4.73471 5.70639 4.70893 5.69562 4.68056C5.68485 4.65218 5.68022 4.62184 5.68204 4.59154V0.840673C5.68061 0.750766 5.64426 0.664942 5.58068 0.60136C5.5171 0.537778 5.43127 0.501427 5.34137 0.5H4.65933C4.56942 0.501427 4.4836 0.537778 4.42001 0.60136C4.35643 0.664942 4.32008 0.750766 4.31865 0.840673V4.59085C4.32047 4.62115 4.31584 4.65149 4.30507 4.67986C4.2943 4.70824 4.27763 4.73401 4.25617 4.75548C4.23471 4.77694 4.20893 4.79361 4.18056 4.80438C4.15218 4.81515 4.12184 4.81978 4.09154 4.81796H0.340673C0.250648 4.81939 0.164722 4.85584 0.101122 4.91957C0.0375211 4.9833 0.00124912 5.0693 0 5.15933V5.84137C0.00142736 5.93127 0.037778 6.0171 0.10136 6.08068C0.164942 6.14426 0.250766 6.18061 0.340673 6.18204H4.09085C4.12115 6.18022 4.15149 6.18485 4.17986 6.19562C4.20824 6.20639 4.23401 6.22306 4.25548 6.24452C4.27694 6.26599 4.29361 6.29176 4.30438 6.32014C4.31515 6.34852 4.31978 6.37886 4.31796 6.40915V10.1593C4.31939 10.2492 4.35574 10.3351 4.41932 10.3986C4.4829 10.4622 4.56873 10.4986 4.65863 10.5H5.34067C5.43058 10.4986 5.51641 10.4622 5.57999 10.3986C5.64357 10.3351 5.67992 10.2492 5.68135 10.1593V6.40915C5.67952 6.37879 5.68417 6.34839 5.69498 6.31996C5.70579 6.29153 5.72252 6.26572 5.74406 6.24425C5.7656 6.22278 5.79146 6.20612 5.81992 6.1954C5.84838 6.18467 5.8788 6.18012 5.90915 6.18204Z"
                                            fill="#0070D2"/>
                                    </svg>
                                    追加
                                </a>

                            </div>
                        </div>
                        {/*<div className="d-flex pt-3">
                            <Form.Label className="mt-2 me-2 w-25 modal-form-label">
                                お弁当パターン
                            </Form.Label>
                            <div className="d-block w-75">
                                <Form.Select className="select-pattern d-inline-block"
                                             {...register(`BentoPatternId`)}>
                                    <option value="0">選択してください</option>
                                    {
                                        patterns && patterns.map(e => {
                                            return <option key={e.id} value={e.id}> {e.Name}</option>
                                        })
                                    }
                                </Form.Select>


                                {isSubmitted &&
                                    <ErrorMessage name="BentoPatternId" className={"px-0 pt-1 mb-0"} errors={errors}/>}

                                <a className="ref-new-pattern"
                                   onClick={() => setIsShowModalPattern(true)}>お弁当パターン追加</a>
                            </div>
                        </div>*/}

                    </Modal.Body>
                    <Modal.Footer className="modal-footer">
                        <Button variant="light" size="sm" className="modal-btn modal`-cancel-btn"
                                onClick={handleCloseModal}>
                            キャンセル
                        </Button>
                        {
                            isSubmitFormCreateCompany ?
                                <Button variant="primary" size="sm" type="submit" className="modal-btn" disabled>
                                    <Spinner
                                        as="span"
                                        animation="grow"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    />
                                    登録...
                                </Button>
                                :
                                <Button variant="primary" size="sm" type="submit" className="modal-btn">
                                    登録
                                </Button>
                        }
                    </Modal.Footer>
                </Form>
            </Modal>
            {
                orgInfo && <div className="pittouch-ex-setting">
                    <Card className="mt-3 card-container">
                        <Card.Body className="page-title">TeamSpirit EX設定</Card.Body>
                    </Card>
                    <div className="mt-3">
                        <Tabs
                            defaultActiveKey="company"
                            activeKey={key}
                            onSelect={(k) => setKey(k)}
                            className="tab-header-ex"
                        >
                            <Tab eventKey="company" title="会社">
                                {!isLoading ?
                                    <ExSettingTabCompany
                                        patterns={patterns}
                                        callbackEditCompany={onCallbackEdit}
                                        callbackEditPattern={onCallbackEditPattern}
                                        records={companies}
                                        onShowModalCreate={(e) => setShowModalAddCompany(e)}/>
                                    :
                                    <h6 className="text-center pt-5 fw-bold">検索中・・・・</h6>
                                }


                            </Tab>
                            {/*<Tab eventKey="pattern" title="お弁当パターン">
                                <ExSettingTabPattern showModal={isShowModalPattern}
                                                     editDataPattern={currentPattern}
                                                     onChangePatternList={e => setPatterns(mapDataPattern(e))}
                                                     changeStatusModal={
                                                         (flag) => {
                                                             setIsShowModalPattern(flag);
                                                             if (!flag) setCurrentPattern(null);
                                                         }
                                                     }
                                                     records={patterns}/>
                            </Tab>*/}
                        </Tabs>
                    </div>
                </div>
            }
        </Fragment>
    )

}