import moment from 'moment';
import {useCallback} from "react";
import messages  from './messages/ja.json';
export interface ProviderSettingInterface {
    ClockMode: number; //時刻表示
    CustomButton: []; //ボタン
    RfidCardMode: number; // 利用カード
    ShortenCode: any[]; //短縮
    StatusAutoTerm: []; //ステータス事項変更1
    StatusMode: number //ステータスボタン
    StatusExtensionMode: any; //食事予約
    WorkLocation?: string;
}

export interface IndividualSettingsInterface {
    WorkLocation: string,
    DeviceId: string
}

export interface WorkLocationInterface {
    UseWorkLocation: boolean,
    WorkLocation?: string,
    UseIndividualSettings: boolean
    IndividualSettings: IndividualSettingsInterface[]
}


export const LIST_PATH_ADMIN = ['/admin-console', '/admin/create-org', '/admin/stations', '/admin/unknown-stations', '/admin/logs','admin/email-list']
export const ENVIRONMENT_TYPE_SANDBOX = 1
export const ENVIRONMENT_TYPE_PRODUCT = 2
export const CONNECT_TYPE_V5 = 1
export const CONNECT_TYPE_EX = 2
export const STATUS_MODE_MANUAL = '99' //手動登録

export const selectBoxStatusCodeButtonCase2 = [
    {value: 1, text: "[出勤]"}, {value: 2, text: "[退勤]"},
    {value: 3, text: "[休憩]/ [外出]"}, {value: 4, text: "[戻り]"}
]

export const selectBoxStatusCodeButtonCase4 = [
    {value: 1, text: "[出勤]"}, {value: 2, text: "[退勤]"},
    {value: 31, text: "[休憩]/ [外出]"}, {value: 32, text: "[戻り]"}
]
export const selectBoxStatusCodeButtonCase6 = [
    {value: 1, text: "[出勤]"}, {value: 2, text: "[退勤]"},
    {value: 21, text: "[休憩]/ [外出]"}, {value: 22, text: "[戻り]"}
]
export const selectBoxStatusCodeButtonCase99 = [
    {value: 1, text: "[出勤]"}, {value: 2, text: "[退勤]"}, {value: 31, text: "[外出]"},
    {value: 32, text: "[外出戻り]"}, {value: 21, text: "[休憩]"}, {value: 22, text: "[休憩戻り]"}
]
export const generateUrlLoginSf = (state: SfGetTokenType, env = ENVIRONMENT_TYPE_PRODUCT) => {
    const responseType = "code"
    const prompt = "login";
    const redirectUri = process.env.REACT_APP_SF_REDIRECT_URL;
    const appUrl = env == ENVIRONMENT_TYPE_PRODUCT ? process.env.REACT_APP_SF_APP_URL : process.env.REACT_APP_SF_APP_URL_SANDBOX;
    console.log(appUrl)
    const clientId = process.env.REACT_APP_SF_CLIENT_ID;
    const urlConnectSalesForce = `${appUrl}?client_id=${clientId}&redirect_uri=${redirectUri}&response_type=${responseType}&prompt=${prompt}&state=${state}`;
    return urlConnectSalesForce;
}


const getTermNoWithCustomStatus = (listStatusAutoTermNo, listCustomStatus, statusMode) => {
    if (statusMode == '2') {
        return selectBoxStatusCodeButtonCase2.findIndex(e => e.value == listStatusAutoTermNo);
    } else if (statusMode == '4') {
        return selectBoxStatusCodeButtonCase4.findIndex(e => e.value == listStatusAutoTermNo);
    } else if (statusMode == '6') {
        return selectBoxStatusCodeButtonCase6.findIndex(e => e.value == listStatusAutoTermNo);
    } else {
        // Case statusMode == '99'
        //Remove all custombutton with value = 0
        const filterListCustomstatus = listCustomStatus.filter(e => e.value != '0');
        return filterListCustomstatus.findIndex(e => e.value == listStatusAutoTermNo);
    }
}

export const generateSettingProvider = (settingProvider: ProviderSettingInterface) => {

    let input = '';
    if (settingProvider.ClockMode !== undefined) {
        input += `\r\nclock.mode=${settingProvider.ClockMode}`
    }
    if (settingProvider.RfidCardMode !== undefined) {
        input += `\r\nrfid.card.mode=${settingProvider.RfidCardMode}`
    }

    if (settingProvider.StatusMode !== undefined) {
        input += `\r\nstatus.mode=${settingProvider.StatusMode}`;
        if (settingProvider.StatusMode == 99) {
            const listCustomButton: any = settingProvider.CustomButton;
            let countListCustomButton = 0;
            let indexCustomButton = 1;
            for (let i = 0; i < listCustomButton.length; i++) {
                if(listCustomButton[i].value != '0') {
                    input += `\r\nstatus.custom.title.${indexCustomButton}=${listCustomButton[i].label}`
                    input += `\r\nstatus.custom.value.${indexCustomButton}=${listCustomButton[i].value}`
                    countListCustomButton++;
                    indexCustomButton++;
                }

            }
            if (countListCustomButton > 0) {
                input += `\r\nstatus.custom.size=${countListCustomButton}`
            }
        }
    }

    if (settingProvider.StatusAutoTerm.length > 0) {
        const listStatusAutoTerm: any = settingProvider.StatusAutoTerm;
        for (let i = 0; i < listStatusAutoTerm.length; i++) {
            if (listStatusAutoTerm[i].active == '1') {
                let noTerm = getTermNoWithCustomStatus(listStatusAutoTerm[i].no, settingProvider.CustomButton, settingProvider.StatusMode);
                console.log(noTerm);
                input += `\r\nstatus.auto.no.${i + 1}=${noTerm + 1}`
                input += `\r\nstatus.auto.term.${i + 1}=${listStatusAutoTerm[i].startTime}-${listStatusAutoTerm[i].endTime}`
            }
        }
    }
    if (settingProvider.ShortenCode.length > 0) {
        let length = 0;
        for (let i = 0; i < settingProvider.ShortenCode.length; i++) {
            if (settingProvider.ShortenCode[i].value !== '') {
                input += `\r\nentry.info.id.menu.${length + 1}=${settingProvider.ShortenCode[i].value}`
                length++;
            }
        }
        if (length > 0) {
            input += `\r\nentry.info.id.menu.size=${length}`
        }
    }

    if (settingProvider.StatusExtensionMode && settingProvider.StatusExtensionMode.active == 1 && settingProvider.StatusMode != 99) {
        input += `\r\nstatus.extension.mode=${settingProvider.StatusExtensionMode.no}`
    }
    return input;
}
export function groupBy(list, keyGetter) {
    const map = new Map();
    list.forEach((item) => {
        if(item.value != '0') {
            const key = keyGetter(item);
            const collection = map.get(key);
            if (!collection) {
                map.set(key, [item]);
            } else {
                collection.push(item);
            }
        }
    });
    return map;
}

export const SfGetTokenAction = ["token", "admin", "user"] as const;
export type SfGetTokenType = typeof SfGetTokenAction[number];

export function compareTwoDate(inputDate1, inputDate2) {
    let date1 = inputDate1
    let date2 = inputDate2
    //convert date1 to string if is string
    if (typeof date1 === "string") {
        date1 = new Date(inputDate1);
    }
    //convert date1 to string if is string
    if (typeof date2 === "string") {
        date2 = new Date(inputDate2);
    }
    var momentA = moment(date1,"DD/MM/YYYY");
    var momentB = moment(date2,"DD/MM/YYYY");
    return moment(momentA).isAfter(momentB, 'day') || moment(momentA).isSame(momentB, 'day');
}

export const redirectUrlLogin = () => {
    if(localStorage.getItem('user-info') ) {
        let userInfo = JSON.parse(localStorage.getItem('user-info') || '');
        localStorage.clear();
        localStorage.setItem('role',userInfo.isSuperAdmin ? 'admin' : 'user')
        window.location.href =  `/${userInfo.isSuperAdmin ? 'admin' : 'user'}/login`;
    } else {
        localStorage.clear();
        window.location.href = generateUrlLoginSf('user');
    }
}

export const checkUrlIsLayoutAdmin = (path): boolean => {
    let result = false;
    for(let i = 0 ; i < LIST_PATH_ADMIN.length ; i++) {
        if(path.indexOf(LIST_PATH_ADMIN[i]) > -1) result = true;
    }
    return result;
}

export function getMessage(code: string) : string {
    return messages[code] || code;
}

export const useYupValidationResolver = validationSchema =>
    useCallback(
        async data => {
            try {
                const values = await validationSchema.validate(data, {
                    abortEarly: false
                });

                return {
                    values,
                    errors: {}
                };
            } catch (errors: any) {
                return {
                    values: {},
                    errors: errors.inner.reduce(
                        (allErrors, currentError) => ({
                            ...allErrors,
                            [currentError.path]: {
                                type: currentError.type ?? "validation",
                                message: currentError.message
                            }
                        }),
                        {}
                    )
                };
            }
        },
        [validationSchema]
    );

export const convertStringHourToSecond = (hourString: string) => {
    const arrHour = hourString.split(":");
    return Number(arrHour[0]) * 60 + Number(arrHour[1]);
}

export const compareRangeTime = (timeOfTerm1, timeOfTerm2) => {
    // Case overnight
    if (convertStringHourToSecond(timeOfTerm1.startTime) >= convertStringHourToSecond(timeOfTerm1.endTime)) {
        if (convertStringHourToSecond(timeOfTerm2.startTime) >= convertStringHourToSecond(timeOfTerm2.endTime)) {
            return true;
        } else {
            return !(convertStringHourToSecond(timeOfTerm2.startTime) >= convertStringHourToSecond(timeOfTerm1.endTime)
                && convertStringHourToSecond(timeOfTerm2.endTime) <= convertStringHourToSecond(timeOfTerm1.startTime))
        }
    } else {
        // start or end time1 not in range of time2
        // example1 : timeOfTerm1 = 08:00AM ~ 10:00AM ,timeOfTerm2 = 06:00AM ~ 11:00AM => false
        // example2 : timeOfTerm1 = 08:00AM ~ 10:00AM ,timeOfTerm2 = 09:00AM ~ 10:00AM => false
        // example3 : timeOfTerm1 = 08:00AM ~ 04:00PM ,timeOfTerm2 = 04:00PM ~ 10:00PM => true
        return ((convertStringHourToSecond(timeOfTerm1.startTime) >= convertStringHourToSecond(timeOfTerm2.startTime)
                && convertStringHourToSecond(timeOfTerm1.startTime) <= convertStringHourToSecond(timeOfTerm2.endTime))
            || (convertStringHourToSecond(timeOfTerm1.endTime) >= convertStringHourToSecond(timeOfTerm2.startTime)
                && convertStringHourToSecond(timeOfTerm1.endTime) <= convertStringHourToSecond(timeOfTerm2.endTime)))
    }
}

export const MESSAGE_PASSWORD_VALIDATE_FORMAT = "パスワードに誤りがあります。パスワードは数字かつ、4桁以上10桁以下で登録してください";
export const MESSAGE_PASSWORD_QR_VALIDATE_FORMAT = "パスワードに誤りがあります。パスワードは数字かつ、4桁以上8桁以下で登録してください";
export const MESSAGE_RE_PASSWORD_NOT_MATCH = "パスワードと確認パスワードが一致しません";
