import "../../App.css";
import { Button, Container, Navbar } from "react-bootstrap";
import logo from "../../assets/images/TS-logo.png";
import { TsAdminContext } from "../../context/ts-admin";
import {useContext, useEffect} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {useAuth} from "../../provider";
import {checkUrlIsLayoutAdmin, LIST_PATH_ADMIN} from "../../common";

export function Header() {
    const [orgInfo, setOrgInfo, userInfo] = useContext(TsAdminContext);
    const location = useLocation().pathname;
    const query = new URLSearchParams(window.location.search);
    const navigate = useNavigate();
    let auth = useAuth();
    const logout = async () => {
        await auth.signout(() => {
        });
    }
    const closeWindow = () => {
        console.log(window.name);
        if(query.get('view') === 'open' || window.name === 'detail-org') {
            localStorage.removeItem('target-org');
            window.close();
        } else {
            navigate(`/admin-console`);
        }
    }
    return (
        userInfo?.isSuperAdmin ?
            <Navbar className="admin-header">
                {
                    !checkUrlIsLayoutAdmin(location) ?
                    <Container>
                        <div className="d-flex flex-column">
                            {
                                query.get('view') !== 'open' ?
                                    <Navbar.Brand className="header-title">TeamSpirit 打刻連携アドオン
                                        管理者コンソール<span style={{
                                            fontWeight: "normal",
                                            fontSize: "16px"
                                        }}>（参照中）</span></Navbar.Brand> :
                                    <Navbar.Brand className="header-title">TeamSpirit 打刻連携アドオン
                                        管理者コンソール<span style={{
                                            fontWeight: "normal",
                                            fontSize: "16px"
                                        }}>（参照中）</span></Navbar.Brand>
                            }

                            <Button variant="light"
                                className="border rounded-0 px-4 admin-back-btn align-self-start"
                                size="sm"
                                    onClick={closeWindow}
                                >
                                閉じる
                            </Button>
                        </div>
                        <Navbar.Collapse className="justify-content-end">
                            <img src={logo} className="ts-logo" />
                        </Navbar.Collapse>
                    </Container>
                    :
                    <Container>
                        <div className="d-flex flex-column">
                            <Navbar.Text>
                                <Navbar.Brand className="header-title">TeamSpirit 打刻連携アドオン
                                    管理者コンソール</Navbar.Brand>
                            </Navbar.Text>
                        </div>
                        <Navbar.Collapse className="justify-content-end">
                            <a className='logout-btn pe-3' onClick={logout}>ログアウト</a>
                            <img src={logo} className="ts-logo" />
                        </Navbar.Collapse>
                    </Container>
                }
            </Navbar> :
            <Navbar className="header">
                <Container>
                    <Navbar.Text>
                        <Navbar.Brand className="header-title">TeamSpirit 打刻連携アドオン 管理コンソール</Navbar.Brand>
                    </Navbar.Text>
                    <Navbar.Collapse className="justify-content-end">
                        <img src={logo} className="ts-logo" />
                    </Navbar.Collapse>
                </Container>
            </Navbar>
    )

}