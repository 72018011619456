export function IconArrow({...props}) {
    const color = !props.active ? '#969492' : '#07487E'
    if (props.type === 'left') {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill={color}
                 className="bi bi-caret-left-fill" viewBox="0 0 16 16">
                <path
                    d="m3.86 8.753 5.482 4.796c.646.566 1.658.106 1.658-.753V3.204a1 1 0 0 0-1.659-.753l-5.48 4.796a1 1 0 0 0 0 1.506z"
                    fill={color} />
            </svg>

        );
    } else {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill={color}
                 className="bi bi-caret-right-fill" viewBox="0 0 16 16">
                <path
                    d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z"
                    fill={color} />
            </svg>
        );
    }

}