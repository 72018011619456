import React, {Fragment, useCallback, useContext, useEffect, useState} from "react";
import {Button, Card, Col, Form, ListGroup, Row, Spinner} from "react-bootstrap";
import "../../App.css";
import {TsAdminContext} from "../../context/ts-admin";
import {updateOrg} from "../../api-service/org-info";
import {toast} from "react-toastify";
import {format, isEqual} from "date-fns";
import {useFieldArray, useForm, useWatch} from "react-hook-form";
import {
    CONNECT_TYPE_V5,
    generateSettingProvider,
    groupBy,
    ProviderSettingInterface,
    selectBoxStatusCodeButtonCase2,
    selectBoxStatusCodeButtonCase4,
    selectBoxStatusCodeButtonCase6,
    selectBoxStatusCodeButtonCase99,
    STATUS_MODE_MANUAL
} from "../../common";
import {Tooltip} from "../../parts/tooltip";
import {PasswordSettingPage} from "./password-setting";
import Modal from "react-bootstrap/Modal";


const convertStringHourToSecond = (hourString: string) => {
    const arrHour = hourString.split(":");
    return Number(arrHour[0]) * 60 + Number(arrHour[1]);
}

export function ProviderSettingPage() {
    const [orgInfo, setOrgInfo, userInfo] = useContext(TsAdminContext);
    const [exportSettingTime, setExportSettingTime] = useState();
    const [modeProvider, setModeProvider] = useState(1); // 0 Screen password , 1 screen provider
    const deviceSettingTooltip = "株式会社スマート・ソリューション・テクノロジー社の提供する勤怠PKGユーザマニュアルでは\n「プロバイダ設定ファイル」になります。";
    const [isLoading, setIsLoading] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [dataUseUserName, setDataUseUserName] = useState<any>(null);
    const [dataProviderSetting, setDataProviderSetting] = useState<any>(null);
    const handleCloseModal = () => {
        setShowModal(false);
    }

    const selectBoxRenderValue = [
        {value: 2, text: "[出勤][退勤]"}, {value: 4, text: "[出勤][退勤][外出][戻り]"},
        {value: 6, text: "[出勤][退勤][休憩][戻り]"}, {value: 99, text: "手動登録"}
    ]

    const selectBoxStatusCodeButton = [
        {value: 0, text: ""},
        {value: 1, text: "出勤"}, {value: 2, text: "退勤"}, {value: 31, text: "外出"},
        {value: 32, text: "外出戻り"}, {value: 21, text: "休憩"}, {value: 22, text: "休憩戻り"}
    ]

    const selectBoxExtensionMode = [
        {value: 1, text: "[注文する][注文しない]"}, {value: 2, text: "[A][B][注文しない]"},
        {value: 3, text: "[A][B][C][注文しない]"}, {value: 4, text: "[A][B][C][D][注文しない]"}
    ]

    const {
        register,
        control,
        handleSubmit,
        reset,
        setValue,
        getValues,
        watch,
        clearErrors,
        formState: {isSubmitting, isDirty}
    } = useForm({
        defaultValues: {
            ShortenCode: [{value: ""}, {value: ""}, {value: ""}, {value: ""}, {value: ""}],
            CustomButton: [
                {value: "1", label: ""},
                {value: "2", label: ""},
                {value: "0", label: ""},
                {value: "0", label: ""},
                {value: "0", label: ""},
                {value: "0", label: ""}
            ],
            StatusAutoTerm: [
                {active: '0', no: '1', startTime: '08:00', endTime: '10:00'},
                {active: '0', no: '1', startTime: '16:00', endTime: '20:00'},
            ],
            StatusExtensionMode: {active: '0', no: '1', startTime: '06:00', endTime: '10:00'},
            useUserName: '0',
            ClockMode: '1',
            RfidCardMode: '0',
            StatusMode: '2',
            UseWorkLocation: '0',
            WorkLocation: ''
        },
        mode: "onBlur",
        reValidateMode: "onChange"

    });

    const {fields: fieldShortenCode}: any = useFieldArray<any>({
        control,
        name: "ShortenCode"
    });

    const {fields: fieldStatusAutoTerm}: any = useFieldArray<any>({
        control,
        name: "StatusAutoTerm"
    });

    const {fields: fieldCustomButton}: any = useFieldArray<any>({
        control,
        name: "CustomButton"
    });
    const watchStatusMode = watch("StatusMode");
    const watchUseWorkLocation = useWatch({control, name: 'UseWorkLocation'});
    const watchStatusAutoTerm = useWatch({control, name: 'StatusAutoTerm'});
    const watchCustomButton = useWatch({control, name: 'CustomButton'});
    const watchStatusExtensionMode = useWatch({control, name: 'StatusExtensionMode'});

    useEffect(() => {
        if (watchUseWorkLocation == '0') {
            setValue('WorkLocation', '');
            setErrorProvider({
                ...errorProvider,
                workLocation: ""
            });
        }
    }, [watchUseWorkLocation])
    const [errorProvider, setErrorProvider] = useState({
        statusButton: [],
        shortenCode: [],
        statusAutoTerm1: "",
        statusAutoTerm2: "",
        statusExtensionMode: "",
        statusAutoTermType1: "",
        statusAutoTermType2: "",
        workLocation: ""
    });

    useEffect(() => {
        const subscription = watch((value, {name, type}) => {
            if (name) {
                const fName = name.split(".");
                if (type === "change" && fName[0] == 'CustomButton' && fName[2] == 'value' && getValues(name) == '0') {
                    setValue(`CustomButton.${Number(fName[1])}.label`, '')
                }
            }
            if (name === 'StatusMode') {
                setValue('StatusAutoTerm.1.no', '1');
                setValue('StatusAutoTerm.0.no', '1');
                setErrorProvider({
                    ...errorProvider,
                    statusButton: [],
                })
            }
        });
        return () => subscription.unsubscribe();
    }, [watch]);

    useEffect(() => {
        if (orgInfo) {
            const providerSetting = orgInfo.currentOrg.ProviderSetting;
            setExportSettingTime(orgInfo.currentOrg.ExportSettingTime);
            const dataShortenCodes = providerSetting?.ShortenCode || [];
            const newShortCode: any = [];
            for (let i = 0; i < dataShortenCodes.length; i++) {
                newShortCode.push({value: dataShortenCodes[i].value})
            }

            if (providerSetting) {
                const useWorkLocation = (providerSetting.WorkLocation && providerSetting.WorkLocation != '') ? '1' : '0';
                reset({
                    ...getValues(),
                    'ClockMode': providerSetting.ClockMode?.toString(),
                    'RfidCardMode': providerSetting.RfidCardMode?.toString(),
                    'StatusMode': providerSetting.StatusMode?.toString(),
                    'StatusAutoTerm': providerSetting.StatusAutoTerm,
                    'StatusExtensionMode': providerSetting.StatusExtensionMode,
                    'CustomButton': providerSetting.CustomButton,
                    'ShortenCode': newShortCode,
                    'WorkLocation': useWorkLocation == '1' ? providerSetting.WorkLocation : '',
                    'UseWorkLocation': useWorkLocation,
                    'useUserName': orgInfo.currentOrg.CardRegistrationIdType.toString()
                })
            }

        }
    }, [orgInfo])


    const compareRangeTime = (timeOfTerm1, timeOfTerm2) => {
        // Case overnight
        if (convertStringHourToSecond(timeOfTerm1.startTime) >= convertStringHourToSecond(timeOfTerm1.endTime)) {
            if(convertStringHourToSecond(timeOfTerm2.startTime) >= convertStringHourToSecond(timeOfTerm2.endTime)) {
                return true;
            } else {
                return !(convertStringHourToSecond(timeOfTerm2.startTime) >= convertStringHourToSecond(timeOfTerm1.endTime)
                    && convertStringHourToSecond(timeOfTerm2.endTime) <= convertStringHourToSecond(timeOfTerm1.startTime))
            }
        } else {
            // start or end time1 not in range of time2
            // example1 : timeOfTerm1 = 08:00AM ~ 10:00AM ,timeOfTerm2 = 06:00AM ~ 11:00AM => false
            // example2 : timeOfTerm1 = 08:00AM ~ 10:00AM ,timeOfTerm2 = 09:00AM ~ 10:00AM => false
            // example3 : timeOfTerm1 = 08:00AM ~ 04:00PM ,timeOfTerm2 = 04:00PM ~ 10:00PM => true
            return ((convertStringHourToSecond(timeOfTerm1.startTime) >= convertStringHourToSecond(timeOfTerm2.startTime)
                    && convertStringHourToSecond(timeOfTerm1.startTime) <= convertStringHourToSecond(timeOfTerm2.endTime))
                || (convertStringHourToSecond(timeOfTerm1.endTime) >= convertStringHourToSecond(timeOfTerm2.startTime)
                    && convertStringHourToSecond(timeOfTerm1.endTime) <= convertStringHourToSecond(timeOfTerm2.endTime)))
        }
    }

    const checkUniqueStatusButton = (listStatusButton) => {
        // reassign id of listStatusButton with index display on screen
        const listGroupByStatusButton = groupBy(listStatusButton, list => list.value);
        let error: any = []
        listGroupByStatusButton.forEach((row) => {
            if (row.length > 1) {
                let strButtonDuplicate = "";
                for (let i = 0; i < row.length; i++) {
                    if (row[i].value != '0') {
                        strButtonDuplicate += i === 0 ? `ボタン${row[i].id}` : `とボタン${row[i].id}`
                    }
                }
                error.push(`${strButtonDuplicate}に同じ区分が設定されています。異なる区分を設定してください。`)
            }
        })
        return error;
    }

    const getStringLen = (str) => {
        var l = str.length;
        var c;
        var length = 0;
        for (var i = 0; i < l; i++) {
            c = str.charCodeAt(i);
            if (0x0000 <= c && c <= 0x0019) {
                length += 0;
            } else if (0x0020 <= c && c <= 0x1FFF) {
                length += 1;
            } else if (0x2000 <= c && c <= 0xFF60) {
                length += 2;
            } else if (0xFF61 <= c && c <= 0xFF9F) {
                length += 1;
            } else if (0xFFA0 <= c) {
                length += 2;
            }
        }
        return length;
    }

    // 全角文字の最大は10数と半角文字の最大は20数
    const checkStatusButtonName = (listStatusButton) => {
        let error: any = []
        listStatusButton.forEach((row) => {
            if (getStringLen(row.label) > 20) error.push(`ボタン${row.id}のカスタムボタン名は半角20文字以内、全角10文字以内で登録してください。`)
        })
        return error;
    }

    const submitFormData = async (data) => {
        let listShortCode: string[] = [];
        const errors: any = {
            statusButton: [],
            statusAutoTerm2: "",
            statusAutoTerm1: "",
            statusExtensionMode: "",
            shortenCode: [],
            statusAutoTermType1: "",
            statusAutoTermType2: "",
            workLocation: ""
        };
        var re = /^[\x20-\x7E]*$/;

        for (let i = 0; i < data.ShortenCode.length; i++) {
            if (!re.test(data.ShortenCode[i].value)) {
                errors.shortenCode[i] = `社員検索時短縮${i + 1}に利用出来ない文字（ASCII印字可能文字以外）は登録出来ません。`
            }
            listShortCode.push(data.ShortenCode[i])
        }
        const providerSetting: ProviderSettingInterface = {
            ClockMode: Number(data.ClockMode),
            CustomButton: data.CustomButton,
            RfidCardMode: Number(data.RfidCardMode),
            ShortenCode: listShortCode,
            StatusAutoTerm: data.StatusAutoTerm,
            StatusExtensionMode: data.StatusExtensionMode,
            StatusMode: Number(data.StatusMode)
        }
        if (data.UseWorkLocation == '1') {
            if (data.WorkLocation != "" && data.WorkLocation.length > 10) {
                errors["workLocation"] = "10文字以内で入力してくだいさい";
            } else {
                providerSetting.WorkLocation = data.WorkLocation
            }
        }

        for (let i = 0; i < data.StatusAutoTerm.length; i++) {
            const tempDate = data.StatusAutoTerm[i];
            if (tempDate.active === '1' && (tempDate.startTime == '' || tempDate.endTime == '')) {
                errors[`statusAutoTerm${i + 1}`] = '変更開始時間帯を入力してください。';
            }
        }

        if ((data.StatusAutoTerm[0].active === '1' && data.StatusAutoTerm[1].active === '1') && data.StatusAutoTerm[0].no === data.StatusAutoTerm[1].no) {
            errors.statusAutoTerm2 = `ステータスボタン デフォルト設定変更1とステータスボタン デフォルト設定変更2で同じ区分が設定されています。異なる区分を設定してください。`;
        }
        if (data.StatusAutoTerm[0].active === '1' && data.StatusAutoTerm[1].active === '1'
            && (compareRangeTime(data.StatusAutoTerm[0], data.StatusAutoTerm[1]) || compareRangeTime(data.StatusAutoTerm[1], data.StatusAutoTerm[0]))) {
            errors.statusAutoTerm2 = `ステータスボタン デフォルト設定変更1とステータスボタン デフォルト設定変更2の変更時間帯の関係に誤りがあります。`;
        }
        if (getValues('StatusMode') == '99') {
            const listCustomButton = data.CustomButton.map((e, index) => {
                e.id = index + 1;
                return e
            })
            if (checkUniqueStatusButton(data.CustomButton).length > 0)
                errors.statusButton.push(...checkUniqueStatusButton(listCustomButton))
            if (checkStatusButtonName(data.CustomButton).length > 0)
                errors.statusButton.push(...checkStatusButtonName(listCustomButton))

            for (let i = 0; i < data.CustomButton.length; i++) {
                if (data.CustomButton[i].value != '0' && data.CustomButton[i].label == '') {
                    errors.statusButton.push(`ボタン${data.CustomButton[i].id}のカスタムボタン名を入力してください。`)
                }
            }

        } else {
            if (data.StatusExtensionMode.active === '1' && (data.StatusExtensionMode.startTime == '' || data.StatusExtensionMode.endTime == '')) {
                errors.statusExtensionMode = '注文可能時間を入力してください。';
            } else if (data.StatusExtensionMode.active === '1'
                && (convertStringHourToSecond(data.StatusExtensionMode.startTime) > convertStringHourToSecond(data.StatusExtensionMode.endTime))) {
                errors.statusExtensionMode = '食事予約の注文可能時間終了時刻は開始時刻の未来時刻を設定してください。';
            }
        }

        // Validate type StatusMode with StatusAutoTerm
        /*
        *{value: 2, text: "[出勤][退勤]"}, {value: 4, text: "[出勤][退勤][外出][戻り]"},
        {value: 6, text: "[出勤][退勤][休憩][戻り]"}, {value: 99, text: "手動登録"}
        * */
        // Case checkin/checkout
        if (data.StatusMode == 2) {
            if (data.StatusAutoTerm[0].active === '1' && (data.StatusAutoTerm[0].no == 3 || data.StatusAutoTerm[0].no == 4)) {
                errors.statusAutoTermType1 = "ステータスボタン デフォルト設定変更1の区分にステータスボタンに表示されてない区分が選択されています。";
            }
            if (data.StatusAutoTerm[1].active === '1' && (data.StatusAutoTerm[1].no == 3 || data.StatusAutoTerm[1].no == 4)) {
                errors.statusAutoTermType2 = "ステータスボタン デフォルト設定変更2の区分にステータスボタンに表示されてない区分が選択されています。";

            }
        }
        if (data.StatusMode == 99) {
            const filterListCustomStatus = data.CustomButton.filter(e => e.value != '0');
            console.log(filterListCustomStatus.find(e => e.value == data.StatusAutoTerm[0].no));
            console.log(filterListCustomStatus.find(e => e.value == data.StatusAutoTerm[1].no));
            if (data.StatusAutoTerm[0].active === '1' && filterListCustomStatus.find(e => e.value == data.StatusAutoTerm[0].no) === undefined) {
                errors.statusAutoTermType1 = "ステータスボタン デフォルト設定変更1の区分にステータスボタンに表示されてない区分が選択されています。";
            }
            if (data.StatusAutoTerm[1].active === '1' && filterListCustomStatus.find(e => e.value == data.StatusAutoTerm[1].no) === undefined) {
                errors.statusAutoTermType2 = "ステータスボタン デフォルト設定変更2の区分にステータスボタンに表示されてない区分が選択されています。";
            }
        }


        if (errors.statusAutoTermType1 !== '' ||
            errors.statusAutoTermType2 !== '' ||
            errors.statusAutoTerm1 !== '' ||
            errors.statusAutoTerm2 !== '' ||
            errors.statusExtensionMode !== '' ||
            errors.shortenCode.length > 0 ||
            errors.workLocation != "" ||
            errors.statusButton.length > 0) {
            setErrorProvider(errors);
            return;
        }
        if (userInfo.isSuperAdmin) {
            setShowModal(true);
            setDataProviderSetting(providerSetting);
            setDataUseUserName(Number(data.useUserName));
        } else {
            await updateProviderSetting(Number(data.useUserName), providerSetting);
        }


    }

    const updateProviderSetting = async (useUserName, providerSetting) => {
        await updateOrg(orgInfo.currentOrg.OrgId, {
            CardRegistrationIdType: Number(useUserName),
            ProviderSetting: providerSetting
        }).then((res: any) => {
            toast.success("保存されました。");
            setOrgInfo({...orgInfo, currentOrg: res.currentOrgInfo.Attributes});
            setErrorProvider({
                statusAutoTerm1: "",
                statusAutoTerm2: "",
                statusExtensionMode: "",
                statusButton: [],
                shortenCode: [],
                statusAutoTermType1: "",
                statusAutoTermType2: "",
                workLocation: ""
            })
        }).catch(err => {
            toast.error("保存処理に失敗しました。再度お試しください。");
        }).finally(() => reset({}, {keepValues: true}))
    }

    const downloadProviderFile = async () => {
        await updateOrg(orgInfo.currentOrg.OrgId, {
            ExportSettingTime: new Date().valueOf(),
        }).then(async (res: any) => {
            const myMarkdownFile = require('../../assets/documents/provider.txt');
            const providerSetting = res.currentOrgInfo.Attributes.ProviderSetting;
            setExportSettingTime(res.currentOrgInfo.Attributes.ExportSettingTime);
            let contentFile = "";
            await fetch(myMarkdownFile)
                .then(response => response.text())
                .then(text => contentFile = text)
            const currentOrgInfo = res.currentOrgInfo.Attributes;
            const deviceAuthNumber = currentOrgInfo.DeviceAuthNumber
            contentFile = contentFile.replaceAll("authen_number=authen_number", `authen_number=${deviceAuthNumber}`);
            if (process.env.REACT_APP_PITTOUCH_API_DEVICE) {
                contentFile = contentFile.replaceAll('{PITTOUCH_API_DEVICE}', process.env.REACT_APP_PITTOUCH_API_DEVICE);
            }
            if (currentOrgInfo.AdminPassword && currentOrgInfo.AdminPassword != '') {
                contentFile += `\r\nadmin.password=${currentOrgInfo.AdminPassword}`;
            }
            contentFile += generateSettingProvider(providerSetting);
            const element = document.createElement("a");
            const file = new Blob([contentFile], {type: 'text/plain'});
            element.href = URL.createObjectURL(file);
            element.download = `provider-${format(new Date(), "yyyy/MM/dd HH:mm")}.txt`;
            document.body.appendChild(element); // Required for this to work in FireFox
            element.click();
        }).catch(err => {
            toast.error("Error : " + err.error);
        })
    }

    const changeMode = (e) => {
        setModeProvider(modeProvider == 0 ? 1 : 0)
    }


    const adminUpdateProviderSetting = async () => {
        setIsLoading(true);
        await updateProviderSetting(dataUseUserName, dataProviderSetting).finally(() => {
            setIsLoading(false)
            setShowModal(false);
        });
    }
    return (
        <Fragment>
            <Modal className="pt-5" show={showModal} onHide={handleCloseModal}>
                <Modal.Header closeButton>

                </Modal.Header>
                <Modal.Body>更新してもよろしいでしょうか？</Modal.Body>
                <Modal.Footer className="modal-footer">
                    <Button variant="light" size="sm" className="modal-btn modal-cancel-btn" onClick={handleCloseModal}>
                        キャンセル
                    </Button>
                    {
                        isLoading ?
                            <Button variant="primary" size="sm" type="button" className="modal-btn" disabled>
                                <Spinner
                                    as="span"
                                    animation="grow"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                />
                                解除...
                            </Button>
                            :
                            <Button variant="primary" size="sm" type="button"
                                    onClick={adminUpdateProviderSetting}
                                    className="modal-btn">
                                更新
                            </Button>
                    }

                </Modal.Footer>
            </Modal>
            <div className="provider-settings">
                <Card className="mt-3 card-container">
                    <Card.Body className="page-title">
                        {modeProvider == 0 ? 'パスワード設定・変更' : 'ICカード端末設定'}
                        {modeProvider == 1 && <Tooltip text={deviceSettingTooltip} left width="552px"/>}

                    </Card.Body>
                </Card>

                {
                    modeProvider == 0 && orgInfo && <Card className="mt-3 card-container">
                        <PasswordSettingPage
                            changeModeProvider={() => {
                                setModeProvider(1)
                            }}/>
                    </Card>
                }
                {
                    modeProvider == 1 && orgInfo && <Card className="mt-3 card-container">

                        <ListGroup variant="flush">
                            <ListGroup.Item className="d-flex align-items-center">
                                <div className="w-25 py-2 item-label">パスワード設定</div>
                                <div key="inline-radio" className="w-50">
                                    <button type={'button'} onClick={changeMode}
                                            className={'btn btn-screen-password'}>パスワード設定・変更
                                    </button>
                                </div>
                            </ListGroup.Item>
                        </ListGroup>
                    </Card>
                }

                {
                    modeProvider == 1 && orgInfo && <Card className="mt-3 card-container">
                        <Form onSubmit={handleSubmit(submitFormData)}>
                            <ListGroup variant="flush">
                                <ListGroup.Item className="d-flex align-items-center">
                                    <div className="w-25 py-2 item-label">社員検索ID</div>
                                    <div key="inline-radio" className="w-50">
                                        <Form.Check
                                            inline
                                            label="ユーザー名"
                                            value={0}
                                            type="radio"
                                            {...register('useUserName')}
                                            className="checkbox-label mb-0 radio-form-check"
                                        />
                                        <Form.Check
                                            inline
                                            label="社員コード"
                                            type="radio"
                                            value={1}
                                            {...register('useUserName')}
                                            className="checkbox-label mb-0 radio-form-check"
                                        />
                                    </div>
                                </ListGroup.Item>


                                <ListGroup.Item className="d-flex align-items-center">
                                    <div className="w-25 py-2 item-label">時刻表示</div>
                                    <div key="inline-radio" className="w-50">
                                        <Form.Check
                                            inline
                                            label="24時間表示"
                                            value={1}
                                            type="radio"
                                            {...register('ClockMode')}
                                            className="checkbox-label mb-0 radio-form-check"
                                        />
                                        <Form.Check
                                            inline
                                            value={0}
                                            label="12時間表示（AM/PM）"
                                            {...register('ClockMode')}
                                            type="radio"
                                            className="checkbox-label mb-0 radio-form-check"
                                        />
                                    </div>
                                </ListGroup.Item>

                                <ListGroup.Item className="d-flex align-items-center">
                                    <div className="w-25 py-2 item-label">利用ICカード</div>
                                    <div key="inline-radio" className="w-50">
                                        <Form.Check
                                            inline
                                            label="FeliCaのみ"
                                            value={0}
                                            {...register('RfidCardMode')}
                                            type="radio"
                                            className="checkbox-label mb-0 radio-form-check"
                                        />
                                        <Form.Check
                                            inline
                                            label="MIFAREのみ"
                                            type="radio"
                                            value={1}
                                            {...register('RfidCardMode')}
                                            className="checkbox-label mb-0 radio-form-check"
                                        />
                                        <Form.Check
                                            inline
                                            label="FeliCaとMIFARE"
                                            value={2}
                                            type="radio"
                                            {...register('RfidCardMode')}
                                            className="checkbox-label mb-0 radio-form-check"
                                        />
                                    </div>
                                </ListGroup.Item>


                                <ListGroup.Item>
                                    <div className="d-flex align-items-center mb-1 mt-1">
                                        <div className="w-25 py-2 item-label">ステータスボタン</div>
                                        <div key="inline-radio" className="w-50">
                                            <Form.Select {...register('StatusMode')}>
                                                {
                                                    selectBoxRenderValue.map((e, index) => {
                                                        return <Fragment key={index}>
                                                            <option value={e.value}>{e.text}</option>
                                                        </Fragment>
                                                    })

                                                }
                                            </Form.Select>
                                        </div>
                                    </div>
                                    {getValues('StatusMode') == '99' && fieldCustomButton.map((item, index) => (
                                        <div key={index} className={`row d-flex ${index > 0 ? 'mt-2' : ''}`}>
                                            <div className="w-25 py-2 item-label">ボタン{index + 1}</div>
                                            <div className="w-75 d-flex align-items-center ">
                                                <div className="me-2 fs-13">区分</div>
                                                <Form.Select className="w-25" {...register(`CustomButton.${index}.value`)}
                                                >
                                                    {
                                                        index > 1 ?
                                                            selectBoxStatusCodeButton.map((e, index1) => {

                                                                return <Fragment key={index1}>
                                                                    <option value={e.value}>{e.text}</option>
                                                                </Fragment>


                                                            }) :
                                                            selectBoxStatusCodeButton.map((e, index1) => {
                                                                if (index1 > 0)
                                                                    return <Fragment key={index1}>
                                                                        <option value={e.value}>{e.text}</option>
                                                                    </Fragment>


                                                            })
                                                    }
                                                </Form.Select>
                                                <div className="py-2 mx-3 fs-13">ボタン名</div>
                                                <Form.Control className="w-50" size="sm"
                                                              maxLength={32}
                                                              disabled={getValues(`CustomButton.${index}.value`) == '0'}
                                                              {...register(`CustomButton.${index}.label`)}></Form.Control>

                                            </div>


                                        </div>
                                    ))}
                                    {
                                        errorProvider.statusButton.length > 0 &&
                                        <div className="mb-2">
                                            {
                                                errorProvider.statusButton.map(e => {
                                                    return <p key={e} className="w-100 error mb-0">{e}</p>
                                                })
                                            }

                                        </div>

                                    }

                                </ListGroup.Item>

                                {watchStatusAutoTerm && fieldStatusAutoTerm.map((item, index) => (
                                    <ListGroup.Item key={index}>
                                        <div className="d-flex align-items-center mb-1 mt-1">
                                            <div className="w-25 py-2 item-label" style={{wordBreak: 'keep-all'}}>ステータスボタン
                                                デフォルト設定変更{index + 1}</div>
                                            <div className="w-75">
                                                <Row className="d-flex">
                                                    <Col xs={12} md={12} lg={12} xxl={6}>
                                                        <Form.Check
                                                            inline
                                                            label="設定しない"
                                                            {...register(`StatusAutoTerm.${index}.active`)}
                                                            type="radio"
                                                            value={0}
                                                            className="checkbox-label mb-0 radio-form-check"
                                                        />
                                                        <Form.Check
                                                            inline
                                                            label="設定する"
                                                            {...register(`StatusAutoTerm.${index}.active`)}
                                                            type="radio"
                                                            value={1}
                                                            className="checkbox-label mb-0 radio-form-check"
                                                        />
                                                        <span className="checkbox-label mb-0 pe-3">区分</span>

                                                        {
                                                            getValues('StatusMode') == '2' &&
                                                            <Form.Select className="auto-term-select-box d-inline-block"
                                                                         disabled={getValues(`StatusAutoTerm.${index}.active`) == '0'}
                                                                         {...register(`StatusAutoTerm.${index}.no`)}>
                                                                {
                                                                    selectBoxStatusCodeButtonCase2.map((e, key) => {
                                                                        return <Fragment key={key}>
                                                                            <option value={e.value}>{e.text}</option>
                                                                        </Fragment>
                                                                    })

                                                                }
                                                            </Form.Select>
                                                        }
                                                        {
                                                            getValues('StatusMode') == '4' &&
                                                            <Form.Select className="auto-term-select-box d-inline-block"
                                                                         disabled={getValues(`StatusAutoTerm.${index}.active`) == '0'}
                                                                         {...register(`StatusAutoTerm.${index}.no`)}>
                                                                {
                                                                    selectBoxStatusCodeButtonCase4.map((e, key) => {
                                                                        return <Fragment key={key}>
                                                                            <option value={e.value}>{e.text}</option>
                                                                        </Fragment>
                                                                    })

                                                                }
                                                            </Form.Select>
                                                        }
                                                        {
                                                            getValues('StatusMode') == '6' &&
                                                            <Form.Select className="auto-term-select-box d-inline-block"
                                                                         disabled={getValues(`StatusAutoTerm.${index}.active`) == '0'}
                                                                         {...register(`StatusAutoTerm.${index}.no`)}>
                                                                {
                                                                    selectBoxStatusCodeButtonCase6.map((e, key) => {
                                                                        return <Fragment key={key}>
                                                                            <option value={e.value}>{e.text}</option>
                                                                        </Fragment>
                                                                    })

                                                                }
                                                            </Form.Select>
                                                        }
                                                        {
                                                            getValues('StatusMode') == '99' &&
                                                            <Form.Select className="auto-term-select-box d-inline-block"
                                                                         disabled={getValues(`StatusAutoTerm.${index}.active`) == '0'}
                                                                         {...register(`StatusAutoTerm.${index}.no`)}>
                                                                {
                                                                    selectBoxStatusCodeButtonCase99.map((e, key) => {
                                                                        return <Fragment key={key}>
                                                                            <option value={e.value}>{e.text}</option>
                                                                        </Fragment>
                                                                    })

                                                                }
                                                            </Form.Select>
                                                        }
                                                    </Col>
                                                    <Col xs={12} md={12} lg={12} xxl={6} className="d-flex mt-2 mt-xxl-0">
                                                        <div className="py-2 mx-3 fs-13"
                                                             style={{wordBreak: 'keep-all'}}>変更時間帯
                                                        </div>


                                                        <div className="w-100 d-flex">

                                                            <Form.Group>
                                                                <Form.Control
                                                                    disabled={getValues(`StatusAutoTerm.${index}.active`) == '0'}
                                                                    type="time" {...register(`StatusAutoTerm.${index}.startTime`)}
                                                                    className="ml-3 mr-5 input-time"/>


                                                            </Form.Group>

                                                            <div className="mx-3">〜</div>
                                                            <Form.Group>
                                                                <Form.Control
                                                                    disabled={getValues(`StatusAutoTerm.${index}.active`) == '0'}
                                                                    type="time" {...register(`StatusAutoTerm.${index}.endTime`)}
                                                                    className="input-time"/>

                                                            </Form.Group>


                                                        </div>
                                                        {/*{errors.StatusAutoTerm && errors.StatusAutoTerm?.[index]?.types && (
                                                    <p className="text-danger w-100 d-flex align-items-center">{errors.StatusAutoTerm?.[index]?.types?.compareDate}</p>
                                                )}
                                                {errors.StatusAutoTerm && errors.StatusAutoTerm?.[index]?.types && (
                                                    <p className="text-danger w-100 d-flex align-items-center">{errors.StatusAutoTerm?.[index]?.types?.compareDate}</p>
                                                )}*/}
                                                    </Col>
                                                </Row>
                                            </div>
                                        </div>
                                        {
                                            errorProvider[`statusAutoTerm${index + 1}`] !== '' &&
                                            <div className="d-flex align-items-center">
                                                <p className="error">{errorProvider[`statusAutoTerm${index + 1}`]}</p>
                                            </div>

                                        }
                                        {
                                            errorProvider[`statusAutoTermType${index + 1}`] !== '' &&
                                            <div className="d-flex align-items-center">
                                                <p className="error">{errorProvider[`statusAutoTermType${index + 1}`]}</p>
                                            </div>

                                        }
                                    </ListGroup.Item>
                                ))}

                                <ListGroup.Item>
                                    <div className="d-flex align-items-center mb-1 mt-1">
                                        <div className="w-25 py-2 item-label">食事予約</div>
                                        <div className="w-75">
                                            {
                                                getValues('StatusMode') == '99' ?
                                                    <Row className="d-flex">
                                                        <Col xs={12}>
                                                            <p className="item-label mb-0 pb-0 fw-normal"
                                                               style={{color: '#EA001E'}}>ステータスボタンが手動登録の場合、食事予約は表示できません</p>
                                                        </Col>
                                                    </Row> : <Row className="d-flex">
                                                        <Col xs={12} md={12} lg={12} xxl={6}>
                                                            <Form.Check
                                                                inline
                                                                label="非表示"
                                                                {...register(`StatusExtensionMode.active`)}
                                                                type="radio"
                                                                value={0}
                                                                className="checkbox-label mb-0 radio-form-check"
                                                            />
                                                            <Form.Check
                                                                inline
                                                                label="表示する"
                                                                {...register(`StatusExtensionMode.active`)}
                                                                type="radio"
                                                                value={1}
                                                                className="checkbox-label mb-0 radio-form-check"
                                                            />
                                                            <Form.Select className="w-50 d-inline-block"
                                                                         disabled={getValues(`StatusExtensionMode.active`) == '0'}
                                                                         {...register(`StatusExtensionMode.no`)}
                                                            >
                                                                {
                                                                    selectBoxExtensionMode.map((e, key) => {
                                                                        return <Fragment key={key}>
                                                                            <option value={e.value}>{e.text}</option>
                                                                        </Fragment>
                                                                    })
                                                                }
                                                            </Form.Select>
                                                        </Col>
                                                        <Col xs={12} md={12} lg={12} xxl={6} className="d-flex mt-2 mt-xxl-0">
                                                            <div className="py-2 mx-3 fs-13"
                                                                 style={{wordBreak: 'keep-all'}}>注文可能時間帯
                                                            </div>
                                                            <div className="w-100 d-flex">
                                                                <Form.Group>
                                                                    <Form.Control
                                                                        disabled={getValues(`StatusExtensionMode.active`) == '0'}
                                                                        type="time" {...register(`StatusExtensionMode.startTime`)}
                                                                        className="ml-3 mr-5 input-time"/>
                                                                </Form.Group>
                                                                <div className="mx-3">〜</div>
                                                                <Form.Group>
                                                                    <Form.Control
                                                                        disabled={getValues(`StatusExtensionMode.active`) == '0'}
                                                                        type="time" {...register(`StatusExtensionMode.endTime`)}
                                                                        className="input-time"/>
                                                                </Form.Group>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                            }

                                        </div>
                                    </div>
                                    {
                                        getValues('StatusMode') != '99' && errorProvider['statusExtensionMode'] !== '' &&
                                        <div className="d-flex align-items-center">
                                            <p className="error">{errorProvider['statusExtensionMode']}</p>
                                        </div>

                                    }
                                </ListGroup.Item>
                                {fieldShortenCode.map((item, index) => (
                                    <ListGroup.Item key={index} className="w-100 d-flex align-items-center">

                                        <div className="w-25 py-2 item-label">社員検索時短縮{index + 1}</div>
                                        <Form.Control className="w-50" size="sm"
                                                      maxLength={32}
                                                      defaultValue={item.value}
                                                      {...register(`ShortenCode.${index}.value`)}
                                        />

                                    </ListGroup.Item>

                                ))}

                                <div className="align-items-center mt-1">
                                    {errorProvider['shortenCode'].map((item, index) => (
                                        <p className={index > 0 ? "error mx-3 pt-0" : "error mx-3 pb-0"}>{item}</p>
                                    ))}
                                </div>

                                {/*{
                                    connectType == CONNECT_TYPE_V5 &&
                                    <ListGroup.Item className="w-100">
                                        <div className="w-100 d-flex align-items-center">
                                            <div className="w-25 py-2 item-label">勤務場所設定</div>
                                            <div className="w-50 d-flex align-items-center">
                                                <Form.Check
                                                    inline
                                                    label="設定しない"
                                                    value={'0'}
                                                    type="radio"
                                                    {...register('UseWorkLocation')}
                                                    className="checkbox-label mb-0 radio-form-check"
                                                />
                                                <Form.Check
                                                    inline
                                                    label="設定する"
                                                    type="radio"
                                                    value={'1'}
                                                    {...register('UseWorkLocation')}
                                                    className="checkbox-label mb-0 radio-form-check"
                                                />


                                                <div className="d-flex align-items-center w-100">
                                                    <div className="w-25">
                                                        <p className="fs-13 mb-0 mt-0">勤務場所コード</p>
                                                    </div>
                                                    <div className="d-flex align-items-center w-75">
                                                        <div className="row">
                                                            <Form.Control
                                                                className={`w-work-location-code w-100 ms-3 ${getValues('UseWorkLocation') == '1' && errorProvider[`workLocation`] ? 'error' : ''}`}
                                                                size="sm"
                                                                disabled={getValues('UseWorkLocation') == '0'}
                                                                {...register(`WorkLocation`)}
                                                            />

                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                        <div className="w-100">
                                            {
                                                getValues('UseWorkLocation') == '1' && errorProvider['workLocation'] !== '' &&
                                                <div className="d-flex align-items-center">
                                                    <p className="error">{errorProvider['workLocation']}</p>
                                                </div>

                                            }
                                        </div>


                                    </ListGroup.Item>
                                }*/}
                                <ListGroup.Item className="d-flex justify-content-center p-4 d-grid gap-5">
                                    <Button type="submit" size="sm"
                                            className={`border px-4 ${(!isDirty || isSubmitting) ? 'btn-disabled not-allowed' : 'btn-save'}`}>
                                        {
                                            isSubmitting && <Spinner
                                                as="span"
                                                animation="border"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                            />
                                        }
                                        保存
                                    </Button>

                                    <Button
                                        onClick={downloadProviderFile} type="button" variant="light"
                                        className={`border px-4 text-primary ${isDirty ? 'btn-disabled not-allowed' : 'btn-export'}`}
                                        size="sm">
                                        出力
                                    </Button>
                                    <div
                                        className="export-time">{exportSettingTime ? "出力日：" + format(new Date(exportSettingTime), "yyyy/MM/dd HH:mm") : ''}</div>
                                </ListGroup.Item>


                            </ListGroup>
                        </Form>

                    </Card>
                }
            </div>
        </Fragment>
    )
}