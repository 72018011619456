import {apiGet, apiPost, apiPut} from "./api";

export const getStations = async (searchParams) => {
    return await apiGet(`/stations`, searchParams);
}

export const getUnknownStations = async (searchParams) => {
    return await apiGet(`/unknown-stations`, searchParams);
}

export const createStation = async (body) => {
    return await apiPost(`/stations`, body);
}

export const updateStation = async (body) => {
    return await apiPut(`/stations`, body);
}
