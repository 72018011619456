import {useParams} from "react-router-dom";
import {Button, Card, Col, Container, Form, Row} from "react-bootstrap";
import {ENVIRONMENT_TYPE_PRODUCT, ENVIRONMENT_TYPE_SANDBOX, generateUrlLoginSf, SfGetTokenAction} from "../../common";
import logoLogin from "../../assets/images/Logo_TeamSpirit.png";
import {useEffect, useState} from "react";

export function LoginPage() {

    const {role}: any = useParams();

    useEffect(() => {
        if (!localStorage.getItem('access-token')) {
            localStorage.removeItem('environment-type');
        }
    }, [])

    const [sfEnvType, setSfEnvType] = useState(ENVIRONMENT_TYPE_PRODUCT);
    if (!SfGetTokenAction.includes(role)) {
        window.location.href = '/404'
    }

    if (localStorage.getItem('access-token')) {
        let userInfo = JSON.parse(localStorage.getItem('user-info') || '');
        if (userInfo.isSuperAdmin) {
            window.location.href = "/admin-console"
        } else {
            window.location.href = "/";
        }
    }

    function handleSubmit() {
        const loginUrl = generateUrlLoginSf(role, sfEnvType);
        window.location.href = loginUrl;
    }

    function changeSfEnvValue(e) {
        setSfEnvType(e.target.value)
        localStorage.setItem('environment-type', e.target.value);
    }

    return (
        <Container className={role === 'admin' ? 'admin-login-container' : 'login-container'}>
            <Row className="vh-100 d-flex justify-content-center align-items-center">
                <Col md={8} lg={6} xs={12}>

                    <div className="mb-5 mt-md-4">
                        <div className="mt-5 mb-5 d-flex">
                            <img src={logoLogin} className="ts-login-logo m-auto" />
                        </div>
                        <div className="description">
                            <p>{role === 'admin' ? 'TeamSpirit 打刻連携アドオン 管理者コンソール' : 'TeamSpirit 打刻連携アドオン 管理コンソール'}</p>
                            <p>ログイン</p>

                        </div>

                        <div className="mt-5">
                            <div className="d-flex">
                                <a onClick={handleSubmit}
                                    className='align-middle m-auto mt-3 btn-login'>
                                    ログイン
                                </a>
                            </div>
                            {
                                role !== 'admin' &&
                                <div className="d-flex mt-3">
                                    <div className='d-flex align-middle m-auto'>
                                        <div key="inline-radio" className="login-radio">
                                            <Form.Check
                                                onChange={changeSfEnvValue}
                                                inline
                                                checked={sfEnvType == ENVIRONMENT_TYPE_PRODUCT}
                                                value={ENVIRONMENT_TYPE_PRODUCT}
                                                label={"本番環境"}
                                                name="sfEnvType"
                                                type={'radio'}
                                                id={`product`}
                                            />
                                        </div>
                                        <div key="inline-radio" className="login-radio">
                                            <Form.Check
                                                inline
                                                checked={sfEnvType == ENVIRONMENT_TYPE_SANDBOX}
                                                value={ENVIRONMENT_TYPE_SANDBOX}
                                                onChange={changeSfEnvValue}
                                                label={"Sandbox"}
                                                name="sfEnvType"
                                                type={'radio'}
                                                id={`sandbox`}
                                            />
                                        </div>
                                    </div>
                                </div>
                            }


                        </div>
                    </div>

                </Col>
            </Row>
        </Container>
    );
}