import {apiGet, apiPost, apiPut} from "./api";

export const getListSetting = async (searchParams,targetOrg) => {
    return await apiGet(`/qr-code-setting${targetOrg ? '?targetOrg='+targetOrg : ''}`, searchParams);
}

export const createQrCodeSetting = async (data: any,targetOrg) => {
    const newData = {...data}
    delete newData.Id;
    return await apiPost(`/qr-code-setting${targetOrg ? '?targetOrg='+targetOrg : ''}`, newData);
}

export const updateQrCodeSetting = async (data, id, targetOrg) => {
    return await apiPut(`/qr-code-setting/${id}${targetOrg ? '?targetOrg='+targetOrg : ''}`, data);
}

export const deleteQrCodeSetting = async (data, id, targetOrg) => {
    return await apiPut(`/qr-code-setting/${id}${targetOrg ? '?targetOrg='+targetOrg : ''}`, data);
}

