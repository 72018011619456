import React, {Fragment, useContext, useEffect, useState} from "react";
import "react-datepicker/dist/react-datepicker.css";
import "react-datetime/css/react-datetime.css";
import 'react-loading-skeleton/dist/skeleton.css'
import {toast} from "react-toastify";
import {TsAdminContext} from "../../context/ts-admin";
import {updateOrg} from "../../api-service/org-info";
import {Button, Spinner} from "react-bootstrap";
import Modal from "react-bootstrap/Modal";


export function QrLogoSetting() {
    const [orgInfo] = useContext(TsAdminContext);

    const [selectedFile, setSelectedFile] = useState<any>(null);
    const [loading, setLoading] = useState(false);

    const [error, setError] = useState("");
    const [logoAttr, setLogAttr] = useState<any>({
        width: 94,
        height: 94
    });
    const HEIGHT_WIDTH_DEFAULT = 94;
    const getWidthAndHeightImage = (w, h) => {
        let newW = HEIGHT_WIDTH_DEFAULT, newH = HEIGHT_WIDTH_DEFAULT;
        if (w > h) {
            newH = (h * HEIGHT_WIDTH_DEFAULT) / w
        } else if (w < h) {
            newW = (w * HEIGHT_WIDTH_DEFAULT) / h
        }
        return {newW, newH}
    }
    useEffect(() => {
        if (orgInfo && orgInfo.currentOrg && orgInfo.currentOrg.Logo) {
            const logoData = orgInfo.currentOrg.Logo;
            if (logoData.content != '') {
                var img = document.createElement("img")
                img.setAttribute("hidden", "true");
                img.setAttribute("src", logoData.content);

                setTimeout(function () {
                    const {newW, newH} = getWidthAndHeightImage(img.width, img.height);
                    setLogAttr({
                        width: newW,
                        height: newH
                    });
                }, 100)

                setSelectedFile(orgInfo.currentOrg.Logo)
            }
        }
    }, [orgInfo])

    function validateImageExtension(file) {
        const allowedExtensions = ["jpg", "png"];
        const extension = file.name.split('.').pop().toLowerCase();
        return allowedExtensions.includes(extension);
    }

    const handleFileChange = (event) => {
        const file = event.target.files[0];

        if (file != undefined) {
            setError("");
            if (!validateImageExtension(file)) {
                setError("ファイル形式に誤りがあります。アップロード可能なファイル形式はJPGかPNGのみです。");
                return;
            }
            //check size image max = 400kb
            const fileSizeInKilobytes = Math.round(file.size / 1024); // Convert to KB
            // Check if the file size is less than 400KB

            if (fileSizeInKilobytes > 2000) {
                setError("ファイルの最大容量を超えました。アップロード可能な容量は2MB未満です");
                return;
            }
            setLogAttr(null);
            const reader: any = new FileReader()
            reader.readAsDataURL(file)
            reader.onload = async () => {
                setLoading(true);

                await updateOrg(orgInfo.currentOrg.OrgId, {
                    Logo: {
                        content: reader.result,
                        name: file.name
                    }
                }).then((res: any) => {
                    setSelectedFile({
                        content: reader.result,
                        name: file.name
                    })
                    var img = document.createElement("img")
                    img.setAttribute("src", reader.result);
                    setTimeout(function () {
                        const {newW, newH} = getWidthAndHeightImage(img.width, img.height);
                        setLogAttr({
                            width: newW,
                            height: newH
                        });
                    }, 100)
                }).catch(err => {
                    console.log(err);
                    toast.error(err.message.error);
                }).finally(() => {
                    setLoading(false);
                });
            }
        }
    };

    const deleteLogo = async () => {
        setLoading(true);
        await updateOrg(orgInfo.currentOrg.OrgId, {
            Logo: {
                content: "",
                name: ""
            }
        }).then((res: any) => {
            setSelectedFile(null);
        }).catch(err => {
            console.log(err);
            toast.error(err.message.error);
        }).finally(() => {
            setLoading(false);
            setShowModalCfDelete(false)
        });
    }
    const [showModalCfDelete, setShowModalCfDelete] = useState(false);

    return (
        <Fragment>
            <Modal size='sm' className="pt-5 form-delete-setting" show={showModalCfDelete}
                   onHide={() => setShowModalCfDelete(false)}>
                <Modal.Header closeButton>
                    <svg fill="#969492" xmlns="http://www.w3.org/2000/svg" className="info-icon"
                         width="15px" height="15px" viewBox="0 0 52 52" enableBackground="new 0 0 52 52">
                        <path d="M26,2C12.7,2,2,12.7,2,26s10.7,24,24,24s24-10.7,24-24S39.3,2,26,2z M26,14.1c1.7,0,3,1.3,3,3s-1.3,3-3,3
                    s-3-1.3-3-3S24.3,14.1,26,14.1z M31,35.1c0,0.5-0.4,0.9-1,0.9h-3c-0.4,0-3,0-3,0h-2c-0.5,0-1-0.3-1-0.9v-2c0-0.5,0.4-1.1,1-1.1l0,0
                    c0.5,0,1-0.3,1-0.9v-4c0-0.5-0.4-1.1-1-1.1l0,0c-0.5,0-1-0.3-1-0.9v-2c0-0.5,0.4-1.1,1-1.1h6c0.5,0,1,0.5,1,1.1v8
                    c0,0.5,0.4,0.9,1,0.9l0,0c0.5,0,1,0.5,1,1.1V35.1z"/>
                    </svg>
                    <span className="ms-2">確認</span>
                </Modal.Header>
                <Modal.Body>
                    <p className="mb-0">写真を削除してよろしいですか？</p>
                </Modal.Body>
                <Modal.Footer className="modal-footer">
                    <Button variant="light" size="sm" className="modal-btn modal-cancel-btn"
                            onClick={() => setShowModalCfDelete(false)}>
                        キャンセル
                    </Button>
                    {
                        loading ?
                            <Button variant="primary" size="sm" type="button" className="modal-btn" disabled>
                                <Spinner
                                    as="span"
                                    animation="grow"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                />
                                削除...
                            </Button>
                            :
                            <Button variant="danger" size="sm" type="button"
                                    onClick={deleteLogo}
                                    className="modal-btn">
                                削除
                            </Button>
                    }

                </Modal.Footer>
            </Modal>
            {
                selectedFile ? <div className="preview-logo">
                        <div className="d-flex align-items-center">
                            <div className="d-flex align-items-center">
                                <div className="m-auto img-logo"
                                     style={{
                                         backgroundImage: `url(${selectedFile.content})`,
                                         backgroundSize: `${logoAttr?.width}px ${logoAttr?.height}px`,
                                         backgroundPosition: "center",
                                         backgroundRepeat: "no-repeat"
                                     }}

                                ></div>

                                {/*<img src={selectedFile.content} width={logoAttr?.width} height={logoAttr?.height} />*/}
                            </div>

                            <span className="file-name mx-2 fs-13">ファイル名：{selectedFile.name}</span>
                        </div>
                        <a className="btn-delete-logo mt-3" onClick={() => setShowModalCfDelete(true)}>写真を削除</a>

                    </div> :
                    <div className="w-100">
                        <div className="file-upload">
                            <div className="form-upload-logo d-flex p-2 align-items-center">
                                <div className="btn-upload-logo p-2 d-flex align-items-center">
                                    {
                                        loading ? <Spinner
                                                as="span"
                                                animation="border"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                            /> :
                                            <svg className="mx-1" width="16" height="17" viewBox="0 0 16 17" fill="none"
                                                 xmlns="http://www.w3.org/2000/svg">
                                                <path fillRule="evenodd" clipRule="evenodd"
                                                      d="M14.9231 9.71079H14C13.7538 9.71079 13.5385 9.95696 13.5385 10.1724V13.2495C13.5385 13.4956 13.3231 13.711 13.0769 13.711H2.92308C2.67693 13.711 2.46154 13.4956 2.46154 13.2495V10.1724C2.46154 9.95696 2.24616 9.71079 2 9.71079H1.07693C0.830772 9.71079 0.615387 9.95696 0.615387 10.1724V14.3265C0.615387 15.0034 1.16923 15.5573 1.84616 15.5573H14.1538C14.8308 15.5573 15.3846 15.0034 15.3846 14.3265V10.1724C15.3846 9.95696 15.1692 9.71079 14.9231 9.71079ZM8.30774 0.911176C8.12313 0.726548 7.8462 0.726548 7.66159 0.911176L3.50774 5.06529C3.32312 5.24992 3.32312 5.52686 3.50774 5.71149L4.15389 6.35768C4.33851 6.54231 4.61543 6.54231 4.80005 6.35768L6.52313 4.63449C6.70774 4.44987 7.07697 4.57295 7.07697 4.84989V11.3734C7.07697 11.6196 7.26159 11.835 7.50774 11.835H8.43082C8.67697 11.835 8.92313 11.5888 8.92313 11.3734V4.88066C8.92313 4.60372 9.23082 4.48064 9.4462 4.66527L11.1693 6.38845C11.3539 6.57308 11.6308 6.57308 11.8154 6.38845L12.4616 5.74226C12.6462 5.55763 12.6462 5.28069 12.4616 5.09606L8.30774 0.911176Z"
                                                      fill="#0176D3"/>
                                            </svg>
                                    }

                                    ファイルをアップロード
                                </div>
                                <span className="ms-3 fs-13">またはファイルをドロップ</span>
                                <input disabled={loading} type="file" onChange={handleFileChange}/>
                            </div>
                        </div>
                    </div>
            }
            {
                error &&
                <div className={`d-flex align-items-center`}>
                    <p className={`error`}>{error}</p>

                </div>
            }
        </Fragment>
    )

}